import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';

// eslint-disable-next-line no-unused-vars
const AuthRoute = ({ isPublic, location, ...rest }) => {
  const isAuthenticated = useSelector(({ session }) => session.isAuthorized);

  if (isAuthenticated) {
    return <Route {...rest} />;
  }
  else {
    return <Redirect to={{ pathname: '/signin', state: { from: location } }} />;
  }
};

export default AuthRoute;
