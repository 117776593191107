import React from'react'
import { Link as MuiLink } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

const Link = ({ style, ...props }) => (
  <MuiLink
    component={RouterLink}
    style={{
      textDecoration: 'none',
      ...style
    }}
    {...props}
  />
);

export default Link;
