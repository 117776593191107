import { AppBar, Toolbar, makeStyles } from '@material-ui/core';
import Logo from '../Logo';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    background:
      theme.palette.type === 'dark'
        ? theme.palette.background.default
        : null,
  },
  logo: {
    height: 24,
    marginRight: theme.spacing(2),
  },
  // offset: theme.mixins.toolbar,
}));

const TopAppBar = () => {
  const { toolbar, logo } = useStyles();

  return (
      <AppBar position='fixed'>
        <Toolbar className={toolbar}>
          <Logo className={logo} variant="inverse" />
        </Toolbar>
      </AppBar>
  );
};

export default TopAppBar;
