import { BaseApiService } from './BaseApiService';

class AgentApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/agent/', access, onError);
  }

  async postSplitPayMint(values) {
    return await this.http.postFetch(`splitpaymints/`, values)
  }

  postSplitPayMint$(values) {
    return this.http.postAjax(`splitpaymints/`, values)
  }

}

export default AgentApiService;
