import { useSelector } from 'react-redux';

// Get the active profile from the current session.
// This is what the user has selected as the default sign-in profile
// or has switched to via the ProfileDrawer.
//  
// We use the term viewer to avoid ambiguity among multiple
// profiles referenced in various social contexts.
//
export default function useViewer() {
  return useSelector(state => state.session?.profile);
}
