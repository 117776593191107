import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Question from './Question'
import QuestionCount from './QuestionCount'
import AnswerOption from './AnswerOption'

const YESNO = [
  {
    type: "y",
    content: "YES"
  },
  {
    type: "n",
    content: "NO"
  }
]

class Quiz extends Component {
  renderAnswerOption = key => {
    return (
      <AnswerOption
        key={key.content}
        answerContent={key.content}
        answerInputs={key.inputs}
        answerType={key.type}
        answer={this.props.answer}
        questionId={this.props.questionId}
        onAnswerSelected={this.props.onAnswerSelected}
      />
    )
  }

  render() {
    const answerOptions = this.props.answerOptions === undefined ? YESNO : this.props.answerOptions
    return (
      <TransitionGroup key={this.props.questionId}>
        <CSSTransition
            classNames="transitioncontainer fade"
            timeout={{ enter:800, exit:500 }}
            appear>
          <div>
            <QuestionCount counter={this.props.questionId} total={this.props.questionTotal} />
            <Question content={this.props.question} />
            <br/>
            <ul className="answerOptions">
              {answerOptions.map(this.renderAnswerOption)}
            </ul>
          </div>
        </CSSTransition>
      </TransitionGroup>
    )
  }
}

Quiz.propTypes = {
  answer: PropTypes.any,
  answerOptions: PropTypes.array,
  inputs: PropTypes.array,
  question: PropTypes.string.isRequired,
  questionId: PropTypes.number.isRequired,
  questionTotal: PropTypes.number.isRequired,
  onAnswerSelected: PropTypes.func.isRequired
}

export default Quiz