import graphql from 'babel-plugin-relay/macro';

export default graphql`
query useBuildConfigVariablesQuery {
  vars: allSecuritiesConfigvariables(filter: {name: {startsWith: "BUILD_"}}) {
    nodes {
      name
      value
    }
  }
}
`;
