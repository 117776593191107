import { useCallback } from 'react';
import { Chip, Paper, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { ORDERS_BY_FILTER_ } from '../state/orders';
import {
  FILTER_BY_OPEN,
  FILTER_BY_ACTIVE,
  FILTER_BY_PAST_24_HOURS,
  FILTER_BY_DATETIME,
  FILTER_ITEM_SELECT_,
} from '../state/filterMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const FilterChip = () => {
  const classes = useStyles();

  const ordersFilters = useSelector((state) => state.filterMenus.OrdersFilters);
  const market = useSelector((state) => state.view.market);
  const timeRange = useSelector((state) => state.timeRanges.TimeRange);

  console.log(ordersFilters);

  const dispatch = useDispatch();
  const selectItem = useCallback(
    (id) => dispatch(FILTER_ITEM_SELECT_(id)),
    [dispatch]
  );
  const ordersByFilter = useCallback(
    (queryParams) => dispatch(ORDERS_BY_FILTER_(queryParams)),
    [dispatch]
  );

  const getQueryParams = (ordersFilters) => {
    let index = 0;
    let queryParams = {};

    ordersFilters.forEach((filter) => {
      // eslint-disable-next-line default-case
      switch (filter.id) {
        case FILTER_BY_OPEN:
          if (filter.selected) {
            queryParams = { ...queryParams, open: 'yes' }
          }
          break;
        case FILTER_BY_ACTIVE:
          if (filter.selected) {
            queryParams = { ...queryParams, market: market }
          }
          break;
        case FILTER_BY_PAST_24_HOURS:
          if (filter.selected) {
            queryParams = { ...queryParams, today: 'yes' }
          }
          break;
        case FILTER_BY_DATETIME:
          if (filter.selected) {
            /* eslint-disable eqeqeq */
            timeRange[0].time =
              timeRange[0].time != ''
                ? timeRange[0].time
                : new Date().toISOString();
            timeRange[1].time =
              timeRange[1].time != ''
                ? timeRange[1].time
                : new Date().toISOString();
            /* eslint-enable eqeqeq */
            queryParams = { ...queryParams, begin: timeRange[0].time, end: timeRange[1].time }
          }
          break;
      }

      index++;
      if (index === ordersFilters.length) {
        ordersByFilter(queryParams);
      }
    });
  };

  const handleDelete = (data, ordersFilters) => () => {
    selectItem(data.id);
    getQueryParams(ordersFilters);
  };

  return (
    <Paper className={classes.root}>
      {
        // eslint-disable-next-line array-callback-return
        ordersFilters && ordersFilters.map((data) => {
          if (data.selected) {
            return (
              <Chip
                key={data.title}
                label={data.title}
                onDelete={handleDelete(data, ordersFilters)}
                className={classes.chip}
              />
            );
          }
        })
      }
    </Paper>
  );
};

export default FilterChip;
