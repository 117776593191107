import { of, ignoreElements } from 'rxjs'
import { SESSION_TERMINATE_ } from './session'

export const handleError = err => {
  console.log('error', err)

  if (err.status === 401) {
    return of(SESSION_TERMINATE_())
  }

  return of(ignoreElements)
}
