import React from 'react';
import { Avatar } from '@material-ui/core';
import Portel from '../icons/Portel.svg';

export default props => {
  return (
    <div {...props}>
      <Avatar src={Portel} alt="PORTEL.US" style={{width:36, height:36}}/>
    </div>
  )
}