import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, MenuItem, Typography, makeStyles } from '@material-ui/core';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import { AssetImage } from '../../components';
import { useIntl } from '../../hooks';

const useStyles = makeStyles(() => ({
  avatar: {
    marginRight: 16,
  },
}));

const FormikAssetField = ({ assets, ...props }) => {
  const classes = useStyles();
  const translate = useIntl();
  return (
    <Field type='text' component={Select} translate={translate} {...props}>
      {Array.from(
        assets
          .map((asset, index) => (
            <MenuItem key={index} value={asset.code}>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <AssetImage
                  component={Avatar}
                  className={classes.avatar}
                  alt={asset.code}
                  src={asset.icon}
                  useStatic={asset.use_static_icon}
                />
                <Typography>{asset.name}</Typography>
              </Box>
            </MenuItem>
          ))
          .values()
      )}
    </Field>
  );
};

FormikAssetField.propTypes = {
  assets: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool
};

export default FormikAssetField;
