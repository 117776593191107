import React from 'react';
import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';

const CheckboxWithDefault = props => {
  const { field: {name}, form: {values} } = props;
  return <CheckboxWithLabel defaultChecked={values[name]} {...props} />;
}

const FormikCheckboxField = ({ name, label }) => (
  <Field
      name={name}
      component={CheckboxWithDefault}
      type="checkbox"
      Label={{ label }}
  />
);

export default FormikCheckboxField;
