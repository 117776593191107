import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Markdown, Stone } from '.';
import { useApiService, useLang } from '../hooks';
import { DocumentApiService } from '../services';

const DocumentAgreeDialog = ({ document_role, open, onAgree, onClose, document_id="0", button_tag="common.agree" }) => {
  const locale = useLang();
  const documentApi = useApiService(DocumentApiService);
  const [document, setDocument] = useState();
  const [notFound, setNotFound] = useState(false);
  const [effectiveLocale, setEffectiveLocale] = useState('en');
  
  useEffect(() => documentApi.getDocument(document_role, document_id, effectiveLocale).then((response) => {
    if (response.ok) {
      setDocument(response.bodyJson)
    }
    else if (locale === 'en') {
      console.log('DocumentApiService', document_role, response.statusText)
      setNotFound(true)
    }
    else {
      setEffectiveLocale('en')
    }
  }), [effectiveLocale]);

  if (!document) {
    return <div />
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='doc-dialog-title'
    >
      <DialogTitle id='doc-dialog-title'>
        {notFound ? 'Not Found' : document.title}
      </DialogTitle>
      <DialogContent>
        <Grid container direction='column' spacing={1}>
          <Markdown>{notFound ? 'Document Not Found' : document.content}</Markdown>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stone
          tag={button_tag}
          component={Button}
          onClick={onAgree}
          variant='contained'
          color='primary'
          autoFocus
        >
          I Agree
        </Stone>
      </DialogActions>
    </Dialog>
  );
};

DocumentAgreeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAgree: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DocumentAgreeDialog;
