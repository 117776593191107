import React from 'react'
import { Box } from '@material-ui/core'

export default ({children, ...props}) => (
  <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      {...props}
  >
    {children}
  </Box>
)