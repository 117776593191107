import { BaseApiService } from './BaseApiService';

class DocumentApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/document/', access, onError);
  }

  getDocument(document_role, id, locale) {
    return this.http.getFetch(`${document_role}/${id}/${locale}`)
  }

}

export default DocumentApiService;
