import { createSelector } from 'reselect';

// Get total unread notifications in all of active user's profiles

const selectUnread = createSelector(
  (state) => state.profiles,
  (state) => state.session.profile.id,
  (profiles, activeProfileId) =>
    profiles.filter(p => p.id === activeProfileId).reduce((acc, p) => acc + p.unread_notification_count, 0)
);

export default selectUnread;
