import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isWidthDown } from '@material-ui/core';
import { useLink, useWidth } from '..';
import navigator from '../../nav';

export function useNavDrawer(navType) {
  const navigate = useSelector((state) => navigator(state));
  const width = useWidth();

  const [open, setOpen] = useState(false);
  const [variant, setVariant] = useState('temporary');
  const pushLink = useLink();

  const handleDrawerOpen = useCallback(() => {
    if (variant !== 'permanent') {
      setOpen(true);
    }
  }, [setOpen, variant]);

  const handleDrawerClose = useCallback(() => {
    if (variant !== 'permanent') {
      setOpen(false);
    }
  }, [setOpen, variant]);

  const handleMouseEnter = useCallback(() => {
    if (!open && navType === 'rail') {
      setOpen(true);
    }
  }, [open, setOpen, navType]);

  const handleMouseLeave = useCallback(() => {
    if (open && navType === 'rail') {
      setOpen(false);
    }
  }, [open, setOpen, navType]);

  const handleNavigate = useCallback(
    (nav) => {
      if (variant !== 'permanent') {
        setOpen(false);
      }
      if (!navigate(nav)) {
        pushLink(`/${nav}`);
      }
    },
    [variant, setOpen, navigate, pushLink]
  );

  return {
    isOpen: open,
    variant,
    handlers: {
      handleDrawerOpen,
      handleDrawerClose,
      handleMouseEnter,
      handleMouseLeave,
      handleNavigate,
    },
  };
}

useNavDrawer.propTypes = {
  navType: PropTypes.oneOf(['bar', 'rail']),
};

export default useNavDrawer;
