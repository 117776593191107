import React from 'react';
import { InfoIcon } from '../icons';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { useIntl, useSimpleDialog } from '../hooks';

const Scores = ({ open }) => {
  const { scores: t_ } = useIntl();
  const [isOpen, handleOpen, handleClose] = useSimpleDialog(!!open);

  return (
    <div>
      <Button
        variant='text'
        color='primary'
        onClick={handleOpen}
        startIcon={<InfoIcon />}
      >
        {t_.info.label}
      </Button>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {/*<DialogTitle id="alert-dialog-title"></DialogTitle>*/}
        <DialogContent>
          <Typography variant='h5'>{t_.info.desc.heading}</Typography>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={t_.info.desc['1']} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t_.info.desc['2']['1']}
                secondary={t_.info.desc['2']['2']}
              />
            </ListItem>
          </List>
          <Typography variant='h5'>{t_.info.play.heading}</Typography>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={t_.info.play['1']} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t_.info.play['2']['1']}
                secondary={t_.info.play['2']['2']}
              />
            </ListItem>
          </List>
          <Typography variant='h5'>{t_.info.scores.heading}</Typography>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t_.info.scores['1']['1']}
                secondary={t_.info.scores['1']['2']}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t_.info.scores['2']['1']}
                secondary={t_.info.scores['2']['2']}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t_.info.scores['3']['1']}
                secondary={t_.info.scores['3']['2']}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t_.info.scores['4']['1']}
                secondary={t_.info.scores['4']['2']}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t_.info.scores['5']['1']}
                secondary={t_.info.scores['5']['2']}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t_.info.scores['6']['1']}
                secondary={t_.info.scores['6']['2']}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t_.info.scores['7']['1']}
                secondary={t_.info.scores['7']['2']}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            {t_.ok}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Scores;
