import { BaseApiService } from './BaseApiService';

class MarketDataApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/md/', access, onError);
  }

  getSummary$ = (market) => {
    return this.http.getAjax(`summary/`, { market: market });
  };

  getSummary = (market) => {
    return this.http.getFetch(`summary/`, { market: market });
  };
}

export default MarketDataApiService;
