import React from 'react'
import { SvgIcon } from '@material-ui/core'

// based on core/static/img/logos/swirl.svg
//   based on core/static/img/logos/white-swirl.png
//
export default props => (
  <SvgIcon viewBox="0 0 64 64" {...props}>
    <g>
      <path d="M22,25h4.59L31,29.41V31.5a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V24.41l3.84-3.83h0A10,10,0,0,0,50.3,20l3.54-3.53a1.05,1.05,0,0,0,.26-.74,1.08,1.08,0,0,0-.33-.74,10,10,0,0,0-14.14,0l-3.34,3.33-.2.2a0,0,0,0,0,0,0L33,21.59V17.4a10,10,0,0,0-.23-13.9L30.64,1.36a1,1,0,0,0-1.41,0,10,10,0,0,0,0,14.14L31,17.28v9.31l-3-3h0A10,10,0,0,0,18,14H13a1,1,0,0,0-1,1A10,10,0,0,0,22,25Z"/>
      <path d="M38.55,42h.15l2.75,1.83A.94.94,0,0,0,42,44a1,1,0,0,0,.55-1.83L40.8,41l1.75-1.17a1,1,0,0,0-1.1-1.66L38.7,40h-.62l2.48-3.1A2,2,0,0,0,41,35.65,2,2,0,0,0,40.16,34a2,2,0,0,0-1.77-.27,5,5,0,0,0-.84.37,3.3,3.3,0,0,1-3.11,0A4.93,4.93,0,0,0,33,33.59a5,5,0,0,0-1-.09,4.84,4.84,0,0,0-1,.09,4.73,4.73,0,0,0-1.45.52A3,3,0,0,1,28,34.5a2.94,2.94,0,0,1-1.55-.39,6.45,6.45,0,0,0-.84-.37,2,2,0,0,0-1.77.27,2,2,0,0,0-.4,2.89L25.92,40H25a1,1,0,0,0,0,2h.45l-9.11,8.41A7.25,7.25,0,0,0,21.26,63H42.74a7.25,7.25,0,0,0,4.92-12.59ZM32,50a3,3,0,0,1,1,5.82V57a1,1,0,0,1-2,0V56H30a1,1,0,0,1,0-2h2a1,1,0,0,0,0-2,3,3,0,0,1-1-5.82V45a1,1,0,0,1,2,0v1h1a1,1,0,0,1,0,2H32a1,1,0,0,0,0,2Z"/>
      <path d="M7,30a1,1,0,0,0-1-1,1,1,0,0,1-1-1,1,1,0,0,0-2,0,1,1,0,0,1-1,1,1,1,0,0,0,0,2,1,1,0,0,1,1,1,1,1,0,0,0,2,0,1,1,0,0,1,1-1A1,1,0,0,0,7,30Z"/>
      <path d="M61,21a1,1,0,0,0-1-1,1,1,0,0,1-1-1,1,1,0,0,0-2,0,1,1,0,0,1-1,1,1,1,0,0,0,0,2,1,1,0,0,1,1,1,1,1,0,0,0,2,0,1,1,0,0,1,1-1A1,1,0,0,0,61,21Z"/>
      <path d="M52,36a1,1,0,0,1-1-1,1,1,0,0,0-2,0,1,1,0,0,1-1,1,1,1,0,0,0,0,2,1,1,0,0,1,1,1,1,1,0,0,0,2,0,1,1,0,0,1,1-1,1,1,0,0,0,0-2Z"/>
      <path d="M15,45a1,1,0,0,0,2,0,1,1,0,0,1,1-1,1,1,0,0,0,0-2,1,1,0,0,1-1-1,1,1,0,0,0-2,0,1,1,0,0,1-1,1,1,1,0,0,0,0,2A1,1,0,0,1,15,45Z"/>
      <path d="M44,9a1,1,0,0,1,1,1,1,1,0,0,0,2,0,1,1,0,0,1,1-1,1,1,0,0,0,0-2,1,1,0,0,1-1-1,1,1,0,0,0-2,0,1,1,0,0,1-1,1,1,1,0,0,0,0,2Z"/>
      <path d="M14,9a1,1,0,0,1,1,1,1,1,0,0,0,2,0,1,1,0,0,1,1-1,1,1,0,0,0,0-2,1,1,0,0,1-1-1,1,1,0,0,0-2,0,1,1,0,0,1-1,1,1,1,0,0,0,0,2Z"/>
      <circle cx="39" cy="4" r="1"/>
      <circle cx="54" cy="50" r="1"/>
      <circle cx="11" cy="52" r="1"/>
      <circle cx="61" cy="35" r="1"/>
      <circle cx="39" cy="28" r="1"/>
      <circle cx="7" cy="40" r="1"/>
      <circle cx="22" cy="30" r="1"/>
      <circle cx="9" cy="19" r="1"/>
    </g>
  </SvgIcon>
)