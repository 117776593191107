import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default () => (
  <SvgIcon version="1.1" x="0px" y="0px" height="35" viewBox="-20 0 108 100">
    <g>
      <circle cx="32.311" cy="11.831" r="10.164"/>
      <circle cx="67.492" cy="11.977" r="10.164"/>
      <path d="M87.393,51.695l-4.241-20.62c-1.122-5.458-6.454-8.975-11.917-7.851c-4.539,0.934-7.724,4.783-8.025,9.191l-2.683,8.757   l-7.848,3.463c2.08,1.236,3.479,3.499,3.479,6.095c0,1.183-0.294,2.294-0.805,3.274l10.92-4.818l1.353,6.574   c0.555,2.695,2.138,4.916,4.254,6.34v29.357c0,3.756,3.045,6.801,6.801,6.801s6.799-3.045,6.799-6.801V59.906   C87.219,57.658,88.01,54.697,87.393,51.695z"/>
      <path d="M50.456,45.767l-9.17-4.044l-3.521-11.491c-0.154-0.505-0.402-0.952-0.691-1.361c-1.321-2.731-3.844-4.842-7.045-5.5   c-5.459-1.125-10.794,2.393-11.917,7.851l-4.239,20.62c-0.636,3.088,0.214,6.133,2.058,8.402v31.654c0,3.756,3.045,6.8,6.801,6.8   s6.801-3.044,6.801-6.8V62.142c2.042-1.426,3.565-3.602,4.106-6.233l1.322-6.427l11.598,5.117c0.634,0.28,1.296,0.412,1.946,0.412   c1.854,0,3.62-1.074,4.418-2.879C53.999,49.692,52.894,46.843,50.456,45.767z"/>
    </g>
  </SvgIcon>
)