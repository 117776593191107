import { useState, useCallback } from 'react';

export default function useSimpleDialog({ open }) {
  const [isOpen, setIsOpen] = useState(!!open);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return [isOpen, handleOpen, handleClose];
}
