import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  Button,
} from '@material-ui/core';
import { Markdown, Stone } from '.';
import { useIntl } from '../hooks';

const DocumentDialog = ({ title, content, isOpen, handleClose }) => {
  // eslint-disable-next-line no-unused-vars
  const translate = useIntl();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='doc-dialog-title'
    >
      <Stone component={DialogTitle} id='doc-dialog-title'>
        {title}
      </Stone>
      <DialogContent>
        <Grid container direction='column' spacing={1}>
          {/* eslint-disable-next-line no-eval */}
          <Markdown>{eval(`translate.${content}`)}</Markdown>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stone
          component={Button}
          onClick={handleClose}
          variant='contained'
          color='primary'
          autoFocus
        >
          common.ok
        </Stone>
      </DialogActions>
    </Dialog>
  );
};

DocumentDialog.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DocumentDialog;
