import React, { useState } from 'react';
import { format } from '../../utils/number';
import { post_text } from '../../utils/utils';
import { useIntl, useWallet } from '../../hooks';
import GraphQL from '../GraphQL';
import ActivitySkeleton from './ActivitySkeleton';
import { RequestActivityDisplay } from './RequestActivityDisplay';
import HandleRequestActivityQuery from './HandleRequestActivityQuery';

import type { HandleRequestActivityQuery$data } from './__generated__/HandleRequestActivityQuery.graphql';
import type {
  ActivityButtonHandlerDictionary,
  ActivityProps,
} from './types';

interface Props extends ActivityProps {
  currency: string;
  notificationId: number;
  quantity: number;
  sender: string;
}

const HandleRequestActivity: React.FC<Props> = ({
  actionId,
  assets,
  currency,
  notificationId,
  quantity: quantity_,
  sender,
  locale,
  onRead,
  time,
  unread,
}) => {
  const translate = useIntl();
  const [nonce, setNonce] = useState(0);
  const asset = assets.get(currency);
  const wallet = useWallet(asset?.code) ?? {available:0};

  console.log(currency, asset, wallet.available, quantity_);

  const handleApprove = () => {
    unread && onRead();
    post_text(`/transaction/accept/hold/${notificationId}/`).then(
      (r) => r.ok && setNonce(1 + nonce)
    );
  };

  const handleDecline = () => {
    unread && onRead();
    post_text(`/transaction/cancel/hold/${notificationId}/`).then(
      (r) => r.ok && setNonce(1 + nonce)
    );
  };

  return (
    <GraphQL
      Waiter={ActivitySkeleton}
      query={HandleRequestActivityQuery}
      variables={{ nonce, rowId: actionId }}
    >
      {({ request }: HandleRequestActivityQuery$data) => {
        const [quantity] = format(quantity_, asset, locale);
        const handlers: ActivityButtonHandlerDictionary =
          request?.status === 'pending'
            ? {
                primary: {
                  caption: translate.dialog.approve,
                  handler: handleApprove,
                  disabled: quantity_ > wallet.available,
                },
                secondary: {
                  caption: translate.dialog.decline,
                  handler: handleDecline,
                },
              }
            : {};
        // eslint-disable-next-line no-unused-vars
        return (
          <RequestActivityDisplay
            asset={asset}
            badgeType='none'
            label='activity.handle_request.label'
            template={translate.activity.handle_request.text}
            variables={{
              sender: sender,
              quantity: quantity,
            }}
            note={request?.note ?? undefined}
            status={`${translate.request.status}: ${
              request?.status
                ? translate.activity.request.status[request.status]
                : 'UNKNOWN STATUS'
            }`}
            handlers={handlers}
            time={time}
            unread={unread}
            waitFor={request?.status !== 'pending'}
            onRead={onRead}
          />
        );
      }}
    </GraphQL>
  );
};

export default HandleRequestActivity;
