import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Button, Typography } from '@material-ui/core';
import { VerifiedUserIcon as MuiVerifiedUserIcon } from '../../icons';
import { useLink, useIntl } from '../../hooks';

const VerifiedUserIcon = ({ profile }) => {
  const translate = useIntl();
  const own = useSelector(state => state.session.profile.id === profile.rowId);
  const color =
    profile.identityVerified === 'approved'
    ? { icon: 'secondary', text: 'secondary' }
    : { icon: 'disabled',  text: 'textSecondary' }
    ;
    const pushLink = useLink();
  
  return (
    <Box>
      <Grid container direction='column' alignItems='center'>
        {own && profile.identityVerified !== 'approved' ? (
          <>
            <Button
                disabled={false && profile.profileCompleteDate === null}
                variant="outlined"
                onClick={() => pushLink('/legacy/verify_identity')}
                color="primary"
                startIcon={<MuiVerifiedUserIcon/>}
            >
              {translate.profile.button_identity_verification}
            </Button>
          </>
        ) : (
          <>
            <MuiVerifiedUserIcon color={color.icon} fontSize='large' />
            <div/>
            <Typography variant='caption' align='center' color={color.text}>
              {translate.profile.identity_verified}
            </Typography>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default VerifiedUserIcon;
