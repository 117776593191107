import React from 'react';
import {
  Container,
  Box,
  Grid,
  Hidden,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Stone } from '../components';

const useStyles = makeStyles(theme => ({
  jumbo: {
    display: 'flex',
    padding: theme.spacing(8, 2, 4, 4),
    color: 'white',
    textShadow: '1px 1px grey',
    backgroundImage: 'url(/static/img/home_page/HomeBackground.jpg)',
    [theme.breakpoints.down('xs')]: {
      backgroundSize: 'auto 100%',
      backgroundPosition: '-40px center'
    },
    [theme.breakpoints.only('sm')]: {
      minHeight: 480,
      backgroundSize: 'cover',
      backgroundPosition: '-40px center'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 600, // matches background image height
      backgroundSize: 'cover',
    },
  },
  transparent: {
    backgroundImage: 'linear-gradient(to left, rgba(0,0,0,0.5), rgba(0,0,0,0))',
  },
  valuePropositions: {
    backgroundImage: 'linear-gradient(rgba(235,78,32,0.1), rgba(0,0,0,0.1))',
  }
}));

const Landing = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg" disableGutters>
      <Box className={classes.jumbo}>
        <Box flexGrow="1"/>
        <Grid container>
          <Hidden xsDown>
            <Grid item sm={5} lg={4}></Grid>
          </Hidden>
          <Grid item xs={12} sm={7} lg={8}>
            <Box p={3} className={classes.transparent} textAlign="right">
              <Stone paragraph variant='h4'>home.headline</Stone>
              <Stone paragraph variant='h5'>home.bullet_1</Stone>
              <Stone paragraph variant='h5'>home.bullet_2</Stone>
              <Stone paragraph variant='h5'>home.bullet_3</Stone>
            </Box>
          </Grid>
          <Grid item xs={7} sm={9} md={10}></Grid>
        </Grid>
      </Box>
      <Box
          p={2}
          className={classes.valuePropositions}>
        <Grid
          container
          direction={smDown ? 'column' : 'row'}
          justifyContent='center'
          alignItems='center'
          spacing={1}
        >
          <Grid
            key='icon1'
            item
            container
            direction={smDown ? 'column' : 'row'}
            justifyContent={smDown ? 'center' : 'flex-start'}
            alignItems='center'
            xs={12}
            md={4}
          >
            <Grid key='icon' item xs={12} sm={4} md={3}>
              <Box
                component='img'
                p={2}
                height={80}
                src='/static/img/home_page/paper.svg'
              />
            </Grid>
            <Grid key='bullets' container item xs={12} sm={8} md={9}>
              <Grid key='head' item xs={12}>
                <Stone
                  align={smDown ? 'center' : 'inherit'}
                  variant='h6'
                >
                  home.subhead_1
                </Stone>
              </Grid>
              <Grid key='bullet_1' item xs={12}>
                <Stone align={smDown ? 'center' : 'inherit'}>
                  home.subbullet_1_1
                </Stone>
              </Grid>
              <Grid key='bullet_2' item xs={12}>
                <Stone align={smDown ? 'center' : 'inherit'}>
                  home.subbullet_1_2
                </Stone>
              </Grid>
              <Grid key='bullet_3' item xs={12}>
                <Stone align={smDown ? 'center' : 'inherit'}>
                  home.subbullet_1_3
                </Stone>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            key='icon2'
            item
            container
            direction={smDown ? 'column' : 'row'}
            alignItems='center'
            xs={12}
            md={4}
          >
            <Grid key='icon' item xs={12} sm={4} md={3}>
              <Box
                component='img'
                p={2}
                height={80}
                src='/static/img/home_page/arrow.svg'
              />
            </Grid>
            <Grid key='bullets' container item xs={12} sm={8} md={9}>
              <Grid key='head' item xs={12}>
                <Stone
                  align={smDown ? 'center' : 'inherit'}
                  variant='h6'
                >
                  home.subhead_2
                </Stone>
              </Grid>
              <Grid key='bullet_1' item xs={12}>
                <Stone align={smDown ? 'center' : 'inherit'}>
                  home.subbullet_2_1
                </Stone>
              </Grid>
              <Grid key='bullet_2' item xs={12}>
                <Stone align={smDown ? 'center' : 'inherit'}>
                  home.subbullet_2_2
                </Stone>
              </Grid>
              <Grid key='bullet_3' item xs={12}>
                <Stone align={smDown ? 'center' : 'inherit'}>
                  home.subbullet_2_3
                </Stone>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            key='icon3'
            item
            container
            direction={smDown ? 'column' : 'row'}
            alignItems='center'
            xs={12}
            md={4}
          >
            <Grid key='icon' item xs={12} sm={4} md={3}>
              <Box
                component='img'
                p={2}
                height={80}
                src='/static/img/home_page/global.svg'
              />
            </Grid>
            <Grid key='bullets' container item xs={12} sm={8} md={9}>
              <Grid key='head' item xs={12}>
                <Stone
                  align={smDown ? 'center' : 'inherit'}
                  variant='h6'
                >
                  home.subhead_3
                </Stone>
              </Grid>
              <Grid key='bullet_1' item xs={12}>
                <Stone align={smDown ? 'center' : 'inherit'}>
                  home.subbullet_3_1
                </Stone>
              </Grid>
              <Grid key='bullet_2' item xs={12}>
                <Stone align={smDown ? 'center' : 'inherit'}>
                  home.subbullet_3_2
                </Stone>
              </Grid>
              <Grid key='bullet_3' item xs={12}>
                <Stone align={smDown ? 'center' : 'inherit'}>
                  home.subbullet_3_3
                </Stone>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Landing;
