import React from 'react'
import Iframe from 'iframe-resizer-react'

/*
   Using min-width to set the width of the IFrame works around an issue in iOS
   that can prevent the iFrame from sizing correctly.
*/

export default ({style, ...props}) => (
  <Iframe style={{ width: '1px', minWidth: '100%', ...style}} {...props} />
)