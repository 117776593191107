import React, { useState, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import { put } from '../utils/utils';
import { DeleteIcon, UploadIcon } from '../icons';
import { useError } from '../hooks';
import { Auth } from '.';

const EditableImageSrc = ({
  api,
  autocrop,
  children,
  // eslint-disable-next-line no-unused-vars
  disabled = true,
  name,
  onSuccess,
  owner,
  src: originalSrc,
}) => {
  const [photoBuffer, setPhotoBuffer] = useState(null);
  const [src, setSrc] = useState(originalSrc);
  const onError = useError();

  const handleCapture = ({ target }) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
      var img = document.createElement('img');
      img.src = e.target.result;
      setPhotoBuffer(img);
    };
    fileReader.onabort = () => {};
    fileReader.onerror = () => {};
  };

  useEffect(() => {
    if (photoBuffer) {
      let width = photoBuffer.width;
      let height = photoBuffer.height;
      let sx = 0;
      let sy = 0;

      var canvas = document.createElement('canvas');
      canvas.width = autocrop.width;
      canvas.height = autocrop.height;
      var ctx = canvas.getContext('2d');

      // resize and crop
      if (canvas.width === canvas.height) {
        if (width > height) {
          sx = Math.floor((width - height) / 2);
          width = height;
        } else {
          sy = Math.floor((height - width) / 2);
          height = width;
        }
      } else {
        if (width > height) {
          width = (height * canvas.width) / canvas.height;
          sx = Math.floor((photoBuffer.width - width) / 2);
        } else {
          height = (width * canvas.height) / canvas.width;
          sy = Math.floor((photoBuffer.height - height) / 2);
        }
      }

      ctx.drawImage(
        photoBuffer,
        sx,
        sy,
        width,
        height,
        0,
        0,
        canvas.width,
        canvas.height
      );
      submit(canvas.toDataURL('image/*'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoBuffer]);

  const handleClearAvatar = () => {
    submit('');
  };

  const submit = (newSrc) =>
    put(api, { [name]: newSrc })
      .then((response) => {
        if (response.ok) {
          setSrc(newSrc);
          onSuccess && onSuccess(name, newSrc);
        } else {
          onError(response.statusText);
        }
      })
      .catch(onError);

  return (
    <>
      <Auth own={owner}>
        <div style={{ position: 'absolute', zIndex: 1000 }}>
          <input
            id={`${name}-image`}
            name={name}
            accept='image/*'
            hidden
            type='file'
            onChange={handleCapture}
          />
          <label htmlFor={`${name}-image`}>
            <IconButton component='span' color='primary'>
              <UploadIcon />
            </IconButton>
          </label>
          <IconButton onClick={handleClearAvatar} color='primary'>
            <DeleteIcon />
          </IconButton>
        </div>
      </Auth>
      {children({ src })}
    </>
  );
};

export default EditableImageSrc;
