import React from 'react';
import { useIntl } from '../../hooks';
import NavItem from './NavItem';

const NavItemList = ({ items, classes, handleNavigate, showText }) => {
  const translate = useIntl();
  
  return (
    <>
      {items.map((n) => (
        <NavItem
          key={n.nav}
          icon={n.icon}
          className={classes.avatar}
          onClick={() => handleNavigate(n.nav)}
          primaryText={translate?.nav[n.nav] ?? n.nav}
          showText={showText}
        />
      ))}
    </>
  );
};

export default NavItemList;
