import React from 'react';
import { createPaginationContainer } from 'react-relay';
import { Button } from '@material-ui/core';
import LedgerEntry from './LedgerEntry';
import { Big } from 'big.js';
import { DateTime } from 'luxon';
import LedgerFeedPaginationQuery from './LedgerFeedPaginationQuery';
import LedgerFeedFragment from './LedgerFeedFragment';
import { useIntl } from '../hooks';

const LedgerFeed = ({ asset, isPrecise, wallet, relay }) => {
  const translate = useIntl();

  function _loadMore() {
    if (!relay.hasMore() || relay.isLoading()) {
      return;
    }

    relay.loadMore(
      25, // fetch next items
      (error) => {
        console.log(error);
      }
    );
  }

  return (
    <div>
      {wallet &&
        wallet.ledger.edges
          .reduce(
            (acc, edge) => {
              const time = DateTime.fromISO(edge.node.tx.time).toLocal();
              const date = 10000 * time.year + 100 * time.month + time.day;
              if (acc.date !== date) {
                acc.all.push({ id: `date-${edge.node.id}`, time });
              }
              const item = {
                id: edge.node.id,
                time,
                type: edge.node.tx.type.model,
                amount: Big(edge.node.creditDebit),
                balance: acc.balance,
              };
              acc.balance = acc.balance.minus(item.amount);
              acc.date = date;
              acc.all.push(item);
              return acc;
            },
            /* initial values */ {
              balance: Big(wallet.balance),
              date: undefined,
              all: [],
            }
          )
          .all.map((item) => (
            <LedgerEntry
              asset={asset}
              isPrecise={isPrecise}
              entry={item}
              key={item.id}
            />
          ))}
      <Button onClick={() => _loadMore()}>{translate?.ledger?.load_more || 'ledger.load_more'}</Button>
    </div>
  );
};

export default createPaginationContainer(
  LedgerFeed,
  {
    wallet: LedgerFeedFragment,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.wallet && props.wallet.ledger;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        orderBy: fragmentVariables.orderBy,
        walletID: props.wallet.id,
      };
    },
    query: LedgerFeedPaginationQuery,
  }
);
