import { BaseApiService } from './BaseApiService';

class JwtApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/jwt/', access, onError);
  }

  refresh = () => {
    const token = localStorage.getItem('auth-refresh');
    return this.http.postFetch('refresh/', { 'refresh': `${token}` });
  };

  refresh$ = () => {
    const token = localStorage.getItem('auth-refresh');
    return this.http.postAjax('refresh/', { 'refresh': `${token}` });
  };

  obtain = ({username, password}) => {
    return this.http.postFetch('obtain/', { username, password })
  }

  obtain$ = ({username, password}) => {
    return this.http.postAjax('obtain/', { username, password })
  }
}

export default JwtApiService;
