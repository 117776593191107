import { setLocaleForNumber } from "./number"
// import moment from 'moment/min/moment-with-locales'

export default function setLocale(locale) {
    if (locale === undefined) {
        return
    }

    // const lang = locale.split('-')[0]

    //
    // Number
    //
    setLocaleForNumber(locale)

    //
    // Moment
    //
    // if (locale !== 'en') {
    //     try {
    //         require('moment/locale/' + locale)
    //         moment.locale(locale)
    //     }
    //     catch(_) {
    //         if (lang != 'en') {
    //             require('moment/locale/' + lang)
    //             moment.locale(lang)
    //         }
    //     }
    // }
    //
    // etc.
    //
    //...
}