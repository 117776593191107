import graphql from 'babel-plugin-relay/macro';
import { createPaginationContainer } from 'react-relay';
import { Activities } from './Activities';

export const PagedFeed = createPaginationContainer(
  Activities,
  {
    stub: graphql`
      fragment PagedFeed_stub on DjangoContentType
      @argumentDefinitions(
        filter: { type: "NotificationsNotificationCondition" }
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "Cursor" }
        orderBy: {
          type: "[NotificationsNotificationsOrderBy!]"
          defaultValue: [PRIMARY_KEY_DESC]
        }
      ) {
        id
        feed: notificationsNotificationsByActorContentTypeId(
          condition: $filter
          first: $count
          after: $cursor
          orderBy: $orderBy # Non-pagination variables
        ) @connection(key: "ActivityStream_feed") {
          edges {
            node {
              id
              rowId
              data
              description
              verb
              timestamp
              unread
              actionId: actionObjectObjectId
              action: djangoContentTypeByActionObjectContentTypeId {
                model
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.stub.feed;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        orderBy: fragmentVariables.orderBy,
        filter: {
          actorObjectId: `${props.profile.id}`,
          ...(props.onlyUnread ? { unread: true } : null)
        },
        id: props.stub.id,
      };
    },
    query: graphql`
      # Pagination query to be fetched upon calling 'loadMore'.
      # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
      query PagedFeedQuery(
        $id: ID!
        $filter: NotificationsNotificationCondition!
        $count: Int!
        $cursor: Cursor
        $orderBy: [NotificationsNotificationsOrderBy!]
      ) {
        stub: node(id: $id) {
          ...PagedFeed_stub
            @arguments(
              filter: $filter
              count: $count
              cursor: $cursor
              orderBy: $orderBy
            )
        }
      }
    `,
  }
);
