import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query useAssetSearchQuery(
    $assetFilter: SecuritiesAssetFilter!
    $classFilter: SecuritiesAssetclassFilter!
    $venueFilter: SecuritiesVenueFilter!
    $perkFilter: SecuritiesDiscretionarygroupFilter!
  ) {
    class: allSecuritiesAssetclasses(filter: $classFilter) {
      nodes {
        rowId
      }
    }
    assets: allSecuritiesAssets(filter: $assetFilter, orderBy: SORT_TERM_ASC) {
      nodes {
        code
        icon
        image: filerImageByFeaturedArtId {
          file: filerFileByFilePtrId {
            path: file
          }
        }
        name
        sortTerm
      }
    }
    venues: allSecuritiesVenues(filter: $venueFilter, orderBy: BOOST_DESC) {
      nodes {
        rowId
        name
        venueType
        description
        image
        target
        targetAsset: securitiesAssetByTargetQuoteAssetId {
          code
          decimals
          notionalValue
        }
        boost
        pocket: securitiesPocketByPocketId {
          sponsor: securitiesProfileByProfileId{
            rowId
            name
            displayName
            photo
          }
          wallets: securitiesWalletsByPocketId {
            nodes {
              balance
              asset: securitiesAssetByAssetId {
                code
                decimals
                notionalValue
              }
              ledger: securitiesLedgersByWalletId(filter: {creditDebit: {greaterThan: "0"}}) {
                nodes {
                  payment: creditDebit
                }
              }
            }
          }
        }
      }
    }
    perks: allSecuritiesDiscretionarygroups(filter: $perkFilter) {
      nodes {
        rowId
        owner: securitiesProfileByProfileId {
          rowId
        }
        name
        description
        visibility
        identity
        membership
        transparency
        image
        duesLifetime
        duesAnnual
        duesMonthly
        duesWeekly
        duesDaily
        duesHourly
        duesMinute
        isFlexibleFee
        isTippable
        isActive
        venue: securitiesVenueByVenueId {
          rowId
          name
          venueType
          description
          image
        }
        profile: securitiesProfileByProfileId {
          rowId
          name
          displayName
          photo
        }
        quote: securitiesNetworkassetByQuoteAssetId {
          asset: securitiesAssetByAssetPtrId {
            code
          }
        }
      }
    }
  }
`;
