import { combineEpics } from 'redux-observable'
import { epics as mqtt } from './mqtt'
import { epics as markets } from './markets'
import { epics as orders } from './orders.js'
import { epics as profiles } from './profiles'
import { epics as friends } from './friends'
import { epics as wallets } from './wallets'
import { epics as fills } from './fills'
import { epics as md } from './md'
import { epics as view } from './view'
import { epics as audio } from './audio'
import { epics as session } from './session'
import { epics as assets } from './assets'
import { epics as links } from './links'
import { epics as relay } from './relay'

export const rootEpic = combineEpics(
  ...view,
  ...md,
  ...mqtt,
  ...markets,
  ...orders,
  ...profiles,
  ...friends,
  ...wallets,
  ...fills,
  ...audio,
  ...session,
  ...assets,
  ...links,
  ...relay,
)
