import { BaseApiService } from './BaseApiService';

class UsersApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/users/', access, onError);
  }

  forgot$ = ({ email }) => {
    return this.http.postAjax('forgot/', { email });
  };

  forgot = ({ email }) => {
    return this.http.postFetch('forgot/', { email });
  };

}

export default UsersApiService;
