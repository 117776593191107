import { ajax } from 'rxjs/ajax';
import { get as getCookie } from 'es-cookie';

// Generic HTTP service for REST APIs. Supports two forms of
// function: observable and async.
//
// Use the function names that end with "Ajax" for the function to
// return an observable using the rxjs/ajax library.
//
// Use the plain function names that end with "Fetch" for the async
// versions that return a promise. The response is exactly like the
// standard 'fetch' response except that an additional .bodyJson member is
// added with the body already converted to json.

const ROOT_URL = '';

export class HttpService {
  headers = {};
  onError = () => {};

  constructor(url_prefix = '', access = '', onError = null) {
    this.url_prefix = url_prefix;
    this.access = access;
    this.onError = onError;
    this.getHeaders();
  }

  getAjax = (url, queryParams = null) => {
    return ajax.getJSON(
      ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
      this.headers
    );
  };

  getFetch = async (url, queryParams = null) => {
    const init = { headers: this.headers };
    return await this.methodFetch(
      ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
      init
    );
  };

  postAjax = (url, body, queryParams = null) => {
    return ajax.post(
      ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
      JSON.stringify(body),
      this.headers
    );
  };

  postFetch = async (url, body, queryParams = null) => {
    const init = {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(body),
    };

    return await this.methodFetch(
      ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
      init
    );
  };

  putAjax = (url, body, queryParams = null) => {
    return ajax.put(
      ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
      JSON.stringify(body),
      this.headers
    );
  };

  putFetch = async (url, body, queryParams = null) => {
    const init = {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify(body),
    };

    return await this.methodFetch(
      ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
      init
    );
  };

  patchAjax = (url, body, queryParams = null) => {
    return ajax.patch(
      ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
      JSON.stringify(body),
      this.headers
    );
  };

  patchFetch = async (url, body, queryParams = null) => {
    const init = {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify(body),
    };
    return await this.methodFetch(
      ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
      init
    );
  };

  deleteAjax = (url, queryParams = null) => {
    return ajax.delete(
      ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
      this.headers
    );
  };

  deleteFetch = async (url, queryParams = null) => {
    const init = {
      method: 'DELETE',
      headers: this.headers,
    };
    return await this.methodFetch(
      ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
      init
    );
  };

  methodFetch = async (input, init) => {
    return await fetch(input, init)
      .then((response) => this.handleErrors(response))
      .then(async (response) => {
        !response.ok && !!this.onError && this.onError(response);
        response.bodyJson = await response.json();
        return response;
      })
      .catch((error) => {
        if (this.onError instanceof Function) {
          this.onError(error);
        }
      });
  };

  handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  getUrl(url) {
    return this.url_prefix + url;
  }

  getHeaders() {
    const cookie = getCookie('csrftoken');

    this.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(this.access
        ? {
            Authorization: `Bearer ${this.access}`,
          }
        : {}),
      ...(!!cookie && cookie !== undefined
        ? {
            'X-CSRFToken': cookie,
          }
        : {}),
    };
  }

  mapQueryParams(queryParams) {
    return queryParams && Object.keys(queryParams).length > 0
      ? '?' +
          Object.keys(queryParams)
            .map(function (key) {
              return key + '=' + encodeURI(queryParams[key]);
            })
            .join('&')
      : '';
  }
}
