import { ofType } from 'redux-observable';
import { of, map, switchMap, catchError } from 'rxjs';
import { Map } from 'immutable';
import { LinksApiService } from '../services';

// action types
//
export const LINKS_UNUSED_FETCH = 'LINKS:UNUSED_FETCH';
export const LINKS_UNUSED_FETCHED = 'LINKS:UNUSED_FETCHED';

// action creators
//
export const LINKS_UNUSED_FETCH_ = (asset_id) => ({
  type: LINKS_UNUSED_FETCH,
  asset_id,
});
export const LINKS_UNUSED_FETCHED_ = (asset_id, data) => ({
  type: LINKS_UNUSED_FETCHED,
  asset_id,
  data,
});

export const reduce = (state = Map(), action) => {
  switch (action.type) {
    default:
      return state;
    case LINKS_UNUSED_FETCHED:
      return state.set(action.asset_id, {
        unused: action.data ? action.data.unused : null,
      });
  }
};

export const epics = [
  (action$) =>
    action$.pipe(
      ofType(LINKS_UNUSED_FETCH),
      switchMap((action) =>
        new LinksApiService().getUnused$(action.asset_id).pipe(
          map((response) => LINKS_UNUSED_FETCHED_(action.asset_id, response)),
          catchError(() => of(LINKS_UNUSED_FETCHED_(action.asset_id, null)))
        )
      )
    ),
];
