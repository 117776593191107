import React, { useState } from 'react';
import { Button, Link } from '@material-ui/core';
import { OpenInNewIcon } from '../icons';

// TODO: Open as popup dialog that can be easily dismissed once read.

const DocumentLink = ({
  field = undefined,
  form = undefined,
  href,
  caption,
}) => {
  const [clicked, setClicked] = useState(field?.value ?? false);
  const color = clicked ? 'secondary' : 'primary';

  const handleEnterKeyOrClick = () => {
    setClicked(true);
    form && form.setFieldValue(field.name, true);
  };

  const handleBlur = () => {
    field && field.onBlur(field.name);
  };

  return (
    <Link
      href={href}
      target='_blank'
      rel='noreferrer noopener'
      underline='none'
    >
      <Button
        variant='outlined'
        color={color}
        endIcon={<OpenInNewIcon color={color} />}
        onKeyPress={(e) => e.charCode === 13 && handleEnterKeyOrClick()}
        onClick={handleEnterKeyOrClick}
        onBlur={handleBlur}
      >
        {caption}
      </Button>
    </Link>
  );
};

export default DocumentLink;
