import { useTheme } from '@material-ui/core';
import useManifest from '../hooks/useManifest'

export default ({variant, ...props}) => {
    const manifest = useManifest();
    const theme = useTheme();

    const suffix = variant === 'inverse' ? (theme.palette.type === 'dark' ? 'light' : 'dark') : variant === undefined ? theme.palette.type : variant;

    return (
        <img
            src={`/brand/logo-${suffix}.svg`}
            alt={manifest?.name ?? ''}
            {...props}
        />
    )
}