import { BaseApiService } from './BaseApiService';

class LinksApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/links/', access, onError);
  }

  getUnused$ = (assetId) => {
    return this.http.getAjax(`${assetId}/unused/`)
  }

  getUnused = (assetId) => {
    return this.http.getFetch(`${assetId}/unused`)
  }
}

export default LinksApiService;
