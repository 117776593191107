import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  isWidthDown,
  makeStyles,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import {
  AdminIcon,
  AspectRatioIcon,
  DashboardIcon,
  DocumentIcon as DocsIcon,
  HelpIcon,
  LinkIcon as LinksIcon,
  ReportIcon,
  SecurityIcon,
  SettingsIcon,
  ToolsIcon,
  ExploreIcon,
  RewardsIcon,
  MAXIcon,
  StreetIcon,
  DocumentIcon,
  LinkIcon,
} from '../../icons';
import { VIEW_LAYOUT_SAVE_, VIEW_MENU_THEME_TOGGLE_ } from '../../state/view';
import { useAction, useIntl, useWidth } from '../../hooks';
import NavItemList from './NavItemList';

const useStyles = makeStyles((theme) => ({
  panel: {
    margin: theme.spacing(2),
    width: 250,
  },
  drawerPaper: {
    zIndex: 0,
    colorBackground: '#121212FF',
    [theme.breakpoints.down('sm')]: { height: 'auto' },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  fab: {
    zIndex: theme.zIndex.drawer + 1,
    margin: 0,
    top: 8,
    right: 8,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed',
  },
  fab_layout: {
    zIndex: theme.zIndex.drawer + 1,
    margin: 0,
    top: 60,
    right: 8,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed',
  },
  avatar: {
    width: 32, //48,
    height: 32, //48,
  },
  bigAvatar: {
    width: 72,
    height: 72,
  },
  button: {
    margin: theme.spacing(1),
  },
  displayName: {
    margin: theme.spacing(1),
  },
  lociAddress: {
    margin: theme.spacing(1),
  },
  badge: {
    colorSecondary: blue[400],
  },
  drawer: {
    backgroundColor: theme.palette.type === 'dark' ? '#121212' : '#FFF',
  },
}));

const MoreDrawer = ({ open, onToggle, drawerHandlers }) => {
  const classes = useStyles();
  const translate = useIntl();
  const isStaff = useSelector(
    (state) => state.session.isAuthorized && state.session.isStaff
  );
  const width = useWidth();

  const saveLayout = useAction(VIEW_LAYOUT_SAVE_);

  const NavSaveLayout = () => {
    return (
      <ListItem button onClick={saveLayout}>
        <ListItemIcon className={classes.icon}>
          <AspectRatioIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <span className={classes.text}>
              {translate.nav.saveLayout || 'Save Layout'}
            </span>
          }
        />
      </ListItem>
    );
  };

  const staffItems = [
    { nav: 'explore', icon: <ExploreIcon className={classes.avatar} /> },
    { nav: 'rewards', icon: <RewardsIcon className={classes.avatar} /> },
    { nav: 'max', icon: <MAXIcon className={classes.avatar} /> },
    { nav: 'street', icon: <StreetIcon className={classes.avatar} /> },
    { nav: 'docs', icon: <DocumentIcon className={classes.avatar} /> },
    { nav: 'tools', icon: <ToolsIcon className={classes.avatar} /> },
    { nav: 'links', icon: <LinkIcon className={classes.avatar} /> },
    { nav: 'dashboard', icon: <DashboardIcon className={classes.avatar} /> },
    { nav: 'reports', icon: <ReportIcon className={classes.avatar} /> },
    { nav: 'admin', icon: <AdminIcon className={classes.avatar} /> },
  ];

  return (
    <Drawer
      PaperProps={{ className: classes.drawer }}
      anchor={isWidthDown('sm', width) ? 'bottom' : 'left'}
      open={open}
      onClose={() => onToggle(false)}
    >
      <Box
        className={classes.panel}
        classes={{ paper: classes.drawerPaper }}
        tabIndex={0}
        role='button'
        onClick={() => onToggle(false)}
        onKeyDown={() => onToggle(false)}
      >
        <List>
          <Route exact path='/max' component={NavSaveLayout} />
          {isStaff && (
            <NavItemList
              items={staffItems}
              classes={classes}
              handleNavigate={drawerHandlers.handleNavigate}
              showText={true}
            />
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default MoreDrawer;
