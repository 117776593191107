import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from '@material-ui/core';
import { DocumentIcon } from '../icons';
import { Markdown, Stone } from '.';
import { useAccordionGroup, useIntl } from '../hooks';
import { useHelpStyles } from '../containers/Help';

// add documents by extending this array
// slugs must be unique
const policies = [
  {
    slug: 'privacy',
    title: 'help.privacy_policy',
    body: 'markdown.privacy_policy',
  },
  {
    slug: 'platform',
    title: 'help.platform_use_agreement',
    body: 'markdown.platform_use_agreement',
  },
  {
    slug: 'asset',
    title: 'help.asset_use_agreement',
    body: 'markdown.asset_use_agreement',
  },
  {
    slug: 'loci_bell',
    title: 'help.loci_bell_agreement',
    body: 'markdown.loci_bell_agreement',
  },
  {
    slug: 'liberty_bell',
    title: 'help.liberty_bell_agreement',
    body: 'markdown.liberty_bell_agreement',
  },
];

const PolicyList = () => {
  const classes = useHelpStyles();
  // eslint-disable-next-line no-unused-vars
  const translate = useIntl();
  const { expanded, handleChange } = useAccordionGroup();

  return (
    <Grid container>
      {policies.map((p) => (
        <Grid
          item
          xs={12}
          key={`${p.slug}-panel`}
          className={classes.paddedBottom}
        >
          <Accordion
            expanded={expanded === `${p.slug}-panel`}
            onChange={handleChange(`${p.slug}-panel`)}
          >
            <AccordionSummary>
              <Grid container direction='row' alignItems='center'>
                <Grid item xs={1}>
                  <DocumentIcon />
                </Grid>
                <Grid item xs={11}>
                  <Stone variant='h6'>{p.title}</Stone>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {expanded === `${p.slug}-panel` && (
                <Grid container>
                  <Grid item xs={12}>
                    {/* eslint-disable-next-line no-eval */}
                    <Markdown>{eval(`translate.${p.body}`)}</Markdown>
                  </Grid>
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
};

export default PolicyList;
