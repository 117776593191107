import { createSelector } from 'reselect'
import { List, Map } from 'immutable'

const selectAssets = state => state.assets
const selectWallets = state => state.wallets
const selectMarkets = state => state.marketprops

export const selectTradableAssets = createSelector(
  selectAssets,
  assets =>
    // really this currently means selectNetworkAssets
    // TODO: hack to use Lxxx assets until asset auth rules are implemented
    assets.reduce(
      (acc, asset, code) => asset.taxonomy[0] === 'networkasset' ? acc.set(code, asset) : acc,
      Map())
)

export const selectSpendableAssets = createSelector(
  selectWallets,
  selectAssets,
  (wallets, assets) => wallets.reduce(
    (acc, position, assetCode) => {
      if (position.available) {
        const asset = assets.get(assetCode);
        if (asset && asset.taxonomy.indexOf('networkasset') > -1) {
          return acc.set(assetCode, asset);
        }
      }
      return acc;
    },
  Map())
);

export const selectAvailableAssets = createSelector(
  selectWallets,
  wallets => wallets.reduce(
    (acc, position, assetCode) => position.available ? acc.push(assetCode) : acc,
    List())
)

const choices = (assetCodeHave, markets) =>
  Object.values(markets).reduce((acc, market) =>
    assetCodeHave === market.base_symbol
    ?
    acc.push({ sellFor: market.quote_symbol })
    :
    assetCodeHave === market.quote_symbol
    ?
    acc.push({ buy: market.base_symbol })
    :
    acc,
    List()
  )

const inverse = (acc, assetCode, choices) =>
  choices.reduce((acc, choice) =>
    choice.sellFor
    ?
    acc.set(choice.sellFor, acc.get(choice.sellFor, List()).push({ buyWith: assetCode }))
    :
    acc.set(choice.buy, acc.get(choice.buy, List()).push({ sell: assetCode })),
  acc)

export const selectPairs = createSelector(
  selectTradableAssets,
  selectMarkets,
  (tradableAssets, markets) => tradableAssets.reduce(
    (acc, x, assetCode) => acc.set(assetCode, choices(assetCode, markets)),
    Map())
)

export const selectHaves = createSelector(
  selectAvailableAssets,
  selectMarkets,
  (availableAssets, markets) => availableAssets.reduce(
    (acc, assetCode) => acc.set(assetCode, choices(assetCode, markets)),
    Map())
)

export const selectAvailableWants = createSelector(
  selectHaves,
  haves => haves.reduce(
    (acc, have, assetCode) => inverse(acc, assetCode, have),
    Map())
)

export const selectWants = createSelector(
  selectPairs,
  pairs => pairs.reduce(
    (acc, have, assetCode) => inverse(acc, assetCode, have),
    Map())
)

export const selectMarketAssets = createSelector(
  selectMarkets,
  markets =>
    Object.values(markets).reduce((acc, market) =>
      acc.set(market.base_symbol, market.base_asset).set(market.quote_symbol, market.quote_asset),
      Map())
)