import React from 'react';
import { Badge, Box, makeStyles } from '@material-ui/core';
import { AssetImage } from '..';
import {
  CancelIcon,
  CheckIcon,
  ExploreIcon,
  PolicyIcon,
  FailIcon,
  PersonIcon,
} from '../../icons';

import type { ActivityAsset, ActivityBadges } from './types';

const useStyles = makeStyles(() => ({
  largeIcon: {
    height: '46px',
    width: '46px',
  },
}));

interface Props {
  asset?: ActivityAsset;
  badgeType: ActivityBadges;
}

function ActivityIcon({ asset, badgeType }: Props) {
  const classes = useStyles();

  const badgeMap: Record<ActivityBadges, JSX.Element> = {
    none: <></>,
    pending: <PolicyIcon color='primary' fontSize='large' />,
    invest: <ExploreIcon color='error' fontSize='large' />,
    cancel: <CancelIcon color='error' fontSize='large' />,
    check: <CheckIcon color='secondary' fontSize='large' />,
    fail: <FailIcon color='error' fontSize='large' />,
  };

  return (
    <Box p={1}>
      {badgeType !== 'none' && (
        <Badge badgeContent={badgeMap[badgeType]} overlap='circular' />
      )}
      {asset ? (
        <AssetImage
          className={classes.largeIcon}
          alt={asset.code}
          src={asset.icon ?? ''}
          useStatic={asset.use_static_icon}
        />
      ) : (
        <PersonIcon className={classes.largeIcon} />
      )}
    </Box>
  );
}

function arePropsEqual(prevProps: Props, nextProps: Props) {
  return (
    ((prevProps.asset?.code || 'none') === (nextProps.asset?.code || 'none')) &&
    (prevProps.badgeType === nextProps.badgeType)
  );
}

export default React.memo(ActivityIcon, arePropsEqual);
