import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query HandlePrivateOfferActivityQuery($rowId: Int!) {
    request: transfersHoldByTransactionPtrId(transactionPtrId: $rowId) {
      note
      status
      typeOf
      exQuantity
      quantity
      asset: securitiesAssetByAssetId {
        code
        decimals
        decimals_min: decimalsMin
        icon
        use_static_icon: useStaticIcon
      }
      exAsset: securitiesAssetByExAssetId {
        code
        decimals
        decimals_min: decimalsMin
        icon
        use_static_icon: useStaticIcon
      }
    }
  }
`;
