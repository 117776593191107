import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useIntl } from '../hooks';

const OrderConfirmationDialog = ({ order, open, onIgnore, onConfirm }) => {
  const translate = useIntl();

  if (!order) {
    return <div />;
  }

  const dnxDescription = `${translate[order.side]} ${order.quantity} ${
    order.symbol
  } ${translate.max.limit} ${order.price}`;

  return (
    <Dialog open={open} onClose={onIgnore}>
      <DialogTitle>{translate.max.order}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dnxDescription}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onIgnore} color='default'>
          {translate.common.back}
        </Button>
        <Button
          onClick={onConfirm}
          color={order.side === 'BUY' ? 'secondary' : 'primary'}
          autoFocus
          variant='contained'
        >
          {translate[order.side]}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderConfirmationDialog;
