import React from 'react';
import {
  Container,
  Grid,
  Divider,
  Typography,
  Box,
  makeStyles
} from '@material-ui/core';
import { useIntl, useDateFns, useBuildConfigVariables } from '../hooks';

const useStyles = makeStyles(theme => ({
  footer: {
    bottom: 0,
    width: '100%',
    backgroundColor: '#262c2e',
    padding: theme.spacing(2),
  },
  footerText: {
    color: 'gray',
    padding: '5px 0px',
    fontSize: '90%',
  },
}));

const AnonFooter = () => {
  const classes = useStyles();
  const translate = useIntl();
  const { format } = useDateFns();
  const { vars: buildVars } = useBuildConfigVariables();

  return (
    <Container className={classes.footer}>
      <Grid container direction='row' justifyContent='space-between'>
        <Grid item >
          <Typography className={classes.footerText}>
            ©{format(Date.now(), "yyyy")} Loci Unifying Systems, Inc.
            <br />
            All Rights Reserved
            <br />
            Used with Permission
          </Typography>
        </Grid>
        <Grid item >
          <Box
            component='img'
            p={2}
            height={72}
            display={{xs: 'none', sm: 'block'}} 
            src='/static/img/logos/Swirl-Footer.svg'
          />
        </Grid>
        <Grid item >
          <Typography align='right' className={classes.footerText} >
            {buildVars?.BUILD_VERSION}
            <br />
            {buildVars?.BUILD_TIME}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Box>
        <Typography className={classes.footerText}>
          {translate.footer.paragraph1}
        </Typography>
        <Typography className={classes.footerText}>
          {translate.footer.paragraph2}
        </Typography>
      </Box>
    </Container>
  );
};

export default AnonFooter;
