import { useState, useEffect } from 'react';
import { fetchQuery } from 'react-relay';
import { environment } from '../relay';
import { useLang } from '.';
import useAssetClassContentQuery from './useAssetClassContentQuery';

export default function useAssetClassContent(assetClassId) {
  const locale = useLang();
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (assetClassId) {
      fetchQuery(environment, useAssetClassContentQuery, {
        assetClassId: assetClassId,
        lang: locale,
      }).subscribe({
        next: (data) => {
          setContent(
            data?.assetClasses?.classcontent?.nodes.reduce(
              (a, n) => ({ ...a, [n.contentName]: n.text }),
              {}
            )
          );
        },
      });
    }
  }, [setContent, assetClassId, locale]);

  return content;
}
