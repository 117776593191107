import React from 'react';
import { Field, getIn } from 'formik';
import NumberFormat from '../NumberFormat';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@material-ui/core';
import Big from 'big.js';

const AssetQuantityInput = props => {
  const {
    asset,
    decimals,
    disabled,
    form: { errors, isSubmitting, setFieldValue, touched },
    field
  } = props;

  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;
  const assetDecimals = asset ? asset.decimals : 2;
  const decimalScale = decimals || assetDecimals;
  console.log(field.value);
  const value =
    field.value === null || field.value === ''
    ? ''
    : Big(field.value).div(10**assetDecimals).toString()
    ;
  const handleValueChange = ({value}) => {
    try {
    const fixed = value === '' ? null : Big(value).mul(10**assetDecimals).toNumber();
    if (fixed !== field.value) {
      setFieldValue(field.name, fixed);
    }
    }
    catch(err) {
      console.log(value, err);
    }
  };

  return (
    <NumberFormat
        value={value}
        {...props}
        error={showError}
        helperText={showError ? fieldError : props.helperText}
        customInput={TextField}
        onValueChange={handleValueChange}
        isNumericString={true}
        disabled={!asset || disabled || isSubmitting}
        decimalScale={decimalScale}
        {...(asset ? {
          InputProps: {
            endAdornment: <InputAdornment position="end">{asset.code}</InputAdornment>
          }
        } : {})}
    />
  );
};

const FormikAssetQuantityInput = props => (
  <Field type="text" component={AssetQuantityInput} {...props} />
);

FormikAssetQuantityInput.propTypes = {
  asset: PropTypes.object.isRequired,
  decimals: PropTypes.number
};

export default FormikAssetQuantityInput;
