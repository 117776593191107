import React from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableRow,
  TableCell,
  Table,
  Typography,
  TableBody,
  Divider,
} from '@material-ui/core';
import { ArrowRightIcon } from '../icons';
import { pv2f, mvpx, mvsz, mvcost } from '../utils/utils';
import { useIntl, useWidth } from '../hooks';

const PrivateRequestConfirmationDialog = ({
  peer,
  memo,
  order,
  open,
  onIgnore,
  onConfirm,
}) => {
  const width = useWidth();
  const activeProfile = useSelector((state) =>
    state.profiles.get(state.session.profileID)
  );
  const translate = useIntl();

  if (!order || !peer) {
    return <div />;
  }

  const pretty = {
    me: `@${activeProfile.name}`,
    peer: `@${peer.name}`,
    px: mvpx(order.market, order.price),
    pxInv: (1.0 / pv2f(order.price, order.market)).toFixed(
      order.market.base_asset.decimals
    ),
    sendAsset: order.buy
      ? order.market.quote_asset.code
      : order.market.base_asset.code,
    recvAsset: order.buy
      ? order.market.base_asset.code
      : order.market.quote_asset.code,
    sendQty: order.buy
      ? mvcost(order.market, order.quantity, order.price)
      : mvsz(order.market, order.quantity),
    recvQty: order.buy
      ? mvsz(order.market, order.quantity)
      : mvcost(order.market, order.quantity, order.price),
  };

  return (
    <Dialog fullScreen={width === 'xs'} open={open} onClose={onIgnore}>
      <DialogTitle>{translate.request.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Send {pretty.sendQty} {pretty.sendAsset} to {pretty.peer} in exchange
          for {pretty.recvQty} {pretty.recvAsset}.
        </DialogContentText>
        <br />
        <Divider />
        <Table size='small'>
          <TableBody>
            <TableRow>
              <TableCell align='center' width='30%'>
                <DialogContentText>{pretty.me}</DialogContentText>
              </TableCell>
              <TableCell align='center' width='40%'>
                <DialogContentText>
                  {pretty.sendQty} {pretty.sendAsset}
                </DialogContentText>
                <ArrowRightIcon />
                <br />
                <br />
                <DialogContentText>
                  {pretty.recvQty} {pretty.recvAsset}
                </DialogContentText>
                <ArrowRightIcon style={{ transform: 'rotate(180deg)' }} />
              </TableCell>
              <TableCell align='center' width='30%'>
                <DialogContentText>{pretty.peer}</DialogContentText>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <br />
        <Typography variant='subtitle1'>
          {translate.request.rate}
        </Typography>

        <Table size='small'>
          <TableBody>
            <TableRow>
              <TableCell align='right'>
                <DialogContentText>{pretty.px}</DialogContentText>
              </TableCell>
              <TableCell align='left'>
                <DialogContentText>
                  {order.market.quote_asset.code} per 1{' '}
                  {order.market.base_asset.code}
                </DialogContentText>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='right'>
                <DialogContentText>{pretty.pxInv}</DialogContentText>
              </TableCell>
              <TableCell align='left'>
                <DialogContentText>
                  {order.market.base_asset.code} per 1{' '}
                  {order.market.quote_asset.code}
                </DialogContentText>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {memo && (
          <React.Fragment>
            <br />
            <Typography variant='subtitle1'>{translate.memo}</Typography>
            <DialogContentText variant='subtitle2'>{memo}</DialogContentText>
          </React.Fragment>
        )}

        <br />
        <DialogContentText variant='body2'>
          By clicking {translate.dialog.submit} below, you thereby propose to{' '}
          {pretty.peer} a non-reversable, non-expiring, cancelable offer.{' '}
          {pretty.sendQty} {pretty.sendAsset} will be immediately transfered
          from your pocket, sent to system escrow, and held in escrow until{' '}
          {pretty.peer} accepts or rejects your offer or until you cancel it.
          <br />
          <br />
          {pretty.peer} will be notified of this offer and may decide to accept
          or reject. If and when {pretty.peer} accepts then, simultaneously, the
          escrowed funds will be released to {pretty.peer} and you will receive{' '}
          {pretty.recvQty} {pretty.recvAsset} from {pretty.peer}.
          <br />
          <br />
          Your offer will become null and void if {pretty.peer} rejects or if
          you cancel the offer before {pretty.peer} accepts it. In either case
          escrowed funds would then be returned to you immediately.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onIgnore} color='default'>
          {translate.common.back}
        </Button>
        <Button
          onClick={onConfirm}
          color='primary'
          autoFocus
          variant='contained'
        >
          {translate.dialog.submit}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrivateRequestConfirmationDialog;
