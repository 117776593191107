import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, IconButton, withStyles } from '@material-ui/core'
import { CloseIcon } from '../icons'
import Quiz from '../components/questionnaire/Quiz'
import './Questionnaire.css'

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  centered: {
    textAlign: 'center'
  },
  button: {
    margin: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const answerValue = answer =>
  'object' === typeof answer ? answer.value : answer

class Questionnaire extends Component {
  constructor(props) {
    super(props)

    const first = Questionnaire.findNextQuestion(0, props.questions, props.filter, props.results)
    const item = props.questions[first]
    const results = props.results || {}

    this.state = {
      counter: first,
      question: item.question,
      answerOptions: item.answers,
      answer: results[item.id],
      results,
      stack: [],
      dirty: false
    }
  }

  valid = answer => {
    if ('object' === typeof answer) {
      for (var value in answer.inputValues) {
        if (answer.inputValues[value] === '') {
          return false
        }
      }
    }
    return true
  }

  handleAnswerSelected = answer => {
    this.setUserAnswer(answer)
    if ('object' !== typeof answer) {
      setTimeout(() => this.handleNext(), 300)
    }
  }

  setUserAnswer = answer => {
    this.setState((state, props) => ({
      results: {
        ...state.results,
        [props.questions[state.counter].id]: answer
      },
      answer: answer,
      dirty: answerValue(answer) !== answerValue(state.answer)
    }))
  }

  handleClose = () => {
    this.props.onClose(this.state.dirty, this.state.results)
  }

  handleBack = () => {
    const counter = this.state.stack.pop()
    const item = this.props.questions[counter]
    this.setState({
      counter,
      question: item.question,
      answerOptions: item.answers,
      answer: this.state.results[item.id]
    })
  }

  handleNext = () => {
    this.state.stack.push(this.state.counter)
    const counter = Questionnaire.findNextQuestion(this.state.counter + 1, this.props.questions, this.props.filter, this.state.results)
    if (counter === this.props.questions.length) {
      this.props.onCompleted(this.state.results)
    }
    else {
      const item = this.props.questions[counter]
      this.setState({
        counter,
        question: item.question,
        answerOptions: item.answers,
        answer: this.state.results[item.id]
      })
    }
  }

  static findNextQuestion(start, questions, filter, results) {
    let counter = start
    if (counter < questions.length) {
      let question = questions[counter]
      while (!filter(question) || (question.iff && !question.iff(results))) {
        if (++counter === questions.length) {
          break
        }
        question = questions[counter]
      }
    }
    return counter
  }

  render() {
    const { classes, questions } = this.props
    const { counter, results } = this.state
    const answer = results[questions[counter].id]

    return (
      <div className={classes.root}>
        <IconButton className={classes.closeButton} onClick={this.handleClose}>
          <CloseIcon />
        </IconButton>
        <Quiz
            answer={this.state.answer}
            answerOptions={this.state.answerOptions}
            questionId={counter + 1}
            question={this.state.question}
            questionTotal={questions.length}
            onAnswerSelected={this.handleAnswerSelected}
        />
        <br/>
        <div>
          <div className="transitioncontainer">
            <Grid container spacing={1} direction="row">
              <Grid item xs={3} className={classes.centered}>
                <Button
                    disabled={this.state.stack.length === 0}
                    variant="contained"
                    onClick={this.handleBack}
                    className={classes.button}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.centered}></Grid>
              <Grid item xs={3} className={classes.centered}>
                <Button
                    disabled={answer === undefined || !this.valid(answer)}
                    variant="contained"
                    onClick={this.handleNext}
                    className={classes.button}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

Questionnaire.propTypes = {
  questions: PropTypes.array.isRequired,
  filter: PropTypes.func,
  results: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired
}

export default withStyles(styles)(Questionnaire)