import React from 'react';

const Image = ({src, alt, ...rest}) => (
  <>
    <img src={src} alt={alt} {...rest} />
  </>
);

const assetIcons = {
  '30CASATXX': 'loci_30lb_tx.svg',
  '30CASATX': 'loci_30lb_tx.svg',
  '30LA': '30la.svg',
  '30LB': 'loci_30lb.svg',
  '30LH': '30lh.svg',
  '30MS160TX': 'loci_30lb_tx.svg',
  '30MS35TX': 'loci_30lb_tx.svg',
  '60CASATXX': 'loci_60lb_tx.svg',
  '60CASATX': 'loci_60lb_tx.svg',
  '60LA': '60la.svg',
  '60LB': 'loci_60lb.svg',
  '60LH': '60lh.svg',
  '60MS160TX': 'loci_60lb_tx.svg',
  '60MS35TX': 'loci_60lb_tx.svg',
  '90CASATXX': 'loci_90lb_tx.svg',
  '90CASATX': 'loci_90lb_tx.svg',
  '90LA': '90la.svg',
  '90LB': 'loci_90lb.svg',
  '90LH': '90lh.svg',
  '90MS160TX': 'loci_90lb_tx.svg',
  '90MS35TX': 'loci_90lb_tx.svg',
  AMD: 'amd.svg',
  ARS: 'ars.svg',
  AUTO: 'auto.svg',
  BRL: 'brl.svg',
  BTC: 'btc.svg',
  CASATXX: 'loci_30lb_tx.svg',
  COP: 'cop.svg',
  CNY: 'cny.svg',
  EGP: 'egp.svg',
  ERROR: 'error.svg',
  ETH: 'eth.svg',
  EUR: 'eur.svg',
  HOME: 'home.svg',
  INR: 'inr.svg',
  IOTA: 'iota.svg',
  LAMD: 'amd.svg',
  LARS: 'ars.svg',
  LB: 'loci_lb.svg',
  LBRL: 'brl.svg',
  LBTC: 'btc.svg',
  LC: 'loci_connect.svg',
  LCOP: 'cop.svg',
  LCNY: 'cny.svg',
  LEGP: 'egp.svg',
  LETH: 'eth.svg',
  LEUR: 'eur.svg',
  LG: 'loci_global.svg',
  LINR: 'inr.svg',
  LIOTA: 'iota.svg',
  LM: 'loci_manager.svg',
  LMXN: 'mxn.svg',
  LP: 'loci_pref.svg',
  LPCORE: 'loci_60lb.svg',
  LRUB: 'rub.svg',
  LSAR: 'sar.svg',
  LSARye: 'sar.svg',
  LT: 'loci_talent.svg',
  LUSD: 'usd.svg',
  LUSDpal: 'usdpal.svg',
  LUSDye: 'usd.svg',
  LVES: 'ves.svg',
  LYER: 'yer.svg',
  MS160TX: 'loci_90lb_tx.svg',
  MS35TX: 'loci_60lb_tx.svg',
  MXN: 'mxn.svg',
  RUB: 'rub.svg',
  SAR: 'sar.svg',
  SARye: 'sar.svg',
  USD: 'usd.svg',
  USDpal: 'usdpal.svg',
  USDye: 'usd.svg',
  VES: 'ves.svg',
  YER: 'yer.svg',
};

const AssetImage = ({
  alt = 'ERROR',
  src = '',
  useStatic = true,
  component = null,
  ...rest
}) => {
  const Component = component ?? Image;

  const source =
    useStatic && assetIcons[alt] !== undefined
    ? `/static/img/svg_icons/${assetIcons[alt]}`
    : src === '' ? `/static/img/svg_icons/${assetIcons['ERROR']}` : src;

  return React.createElement(Component, {src: source, alt: alt ?? 'ERROR', ...rest});
};

export default AssetImage;
