import React from 'react'
import { Badge, BottomNavigation, BottomNavigationAction, makeStyles } from "@material-ui/core"
import { ActivityIcon, HomeIcon, MoreIcon, OrgIcon, PersonIcon, PocketIcon } from '../icons'
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import selectUnread from '../selectors/selectUnread';

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 100
  }
}));

const BottomNav = ({drawerHandlers}) => {
  const classes = useStyle();
  const profile = useSelector((state) => state.session.profile);
  const unreadCount = useSelector((state) => selectUnread(state));
  //const otherUnreadCount = useSelector((state) => selectOtherUnread(state));
  // eslint-disable-next-line no-unused-vars
  //const isCapturingAudience = !!useSelector((state) => state.audience);

  const { handleDrawerOpen } = drawerHandlers

  const Home = () => (
    <Badge
      badgeContent={unreadCount}
      overlap='circular'
      color='error'
    >
      <HomeIcon />
    </Badge>
  )

  return (
    <BottomNavigation
        className={classes.root}
        value={useLocation().pathname}
        onChange={(event, newValue) => {
          if (newValue === 'more') {
            handleDrawerOpen()
          }
        }}
        showLabels
    >
      <BottomNavigationAction component={Link} to="/home" value="/home" label="Home" icon={<Home />} />
      <BottomNavigationAction component={Link} to="/activity" value="/activity" label="Activity" icon={<ActivityIcon />} />
      <BottomNavigationAction component={Link} to="/pocket" value="/pocket" label="Pocket" icon={<PocketIcon />} />
      <BottomNavigationAction component={Link} to="/profile" value="/profile" label="Profile" icon={profile.profile_type === 'PERSONAL' ? <PersonIcon /> : <OrgIcon/>} />
    </BottomNavigation>
  )
}

export default BottomNav