import React from 'react';
import OnVisible from 'react-on-visible';
import { ActivityProps } from './types';

const UNREAD_TIMEOUT = 2500;

interface Props extends Pick<ActivityProps, 'unread' | 'onRead'> {
  waitFor?: boolean;
}

const MarkReadOnVisible: React.FC<Props> = ({
  unread,
  onRead,
  waitFor = true,
  children,
}) =>
  unread && waitFor && onRead ? (
    <OnVisible
      onChange={(isVisible) => isVisible && setTimeout(onRead, UNREAD_TIMEOUT)}
    >
      {children}
    </OnVisible>
  ) : (
    <>{children}</>
  );

export default MarkReadOnVisible;
