import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Dialog, DialogTitle } from '@material-ui/core';
import Questionnaire from '../containers/Questionnaire';
import { useIntl } from '../hooks';
// eslint-disable-next-line no-unused-vars
import { count, yes } from '../utils/questionnaire';

const AssetQuestionnaireDialog = ({
  // eslint-disable-next-line no-unused-vars
  asset,
  open,
  onClose,
  onCompleted,
  questions,
  results,
}) => {
  const translate = useIntl();
  const profile = useSelector((state) => state.session.profile);

  const filter = (q) => {
    return (
      ((q.individual && profile.profile_type === 'PERSONAL') ||
        (q.entity && profile.profile_type === 'ORGANIZATIONAL')) &&
      (q.scope === 'ALL' || q.scope === profile.reg_policy)
    );
  };

  // BEGIN variables used in questionnaire text
  //
  /* eslint-disable no-unused-vars */
  const subscriberType =
    profile.profile_type === 'PERSONAL' ? 'INDIVIDUAL' : 'ENTITY';
  const subscriberTypeOther =
    profile.profile_type === 'PERSONAL' ? 'ENTITY' : 'INDIVIDUAL';
  // also count and yes from ../utils/questionnaire
  const yes = (results, id) => (results[id] === 'y' ? 1 : 0);
  const count = (results, ids) => ids.reduce((acc, id) => acc + yes(results, id), 0);
  //
  // END variables used in questionnaire text
  //
  // eslint-disable-next-line no-eval
  const compiled = eval(questions) || [];
  const expanded = compiled.map((item) => ({
    ...item,
    // eslint-disable-next-line no-eval
    question: eval('`' + item.question + '`'),
  }));

  return (
    <Dialog open={open} fullScreen>
      <DialogTitle style={{ textAlign: 'center' }}>
        {translate.subscribe.step_questionnaire}
      </DialogTitle>
      <Questionnaire
        questions={expanded}
        results={results}
        filter={filter}
        onClose={onClose}
        onCompleted={onCompleted}
      />
    </Dialog>
  );
};

AssetQuestionnaireDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCompleted: PropTypes.func,
  results: PropTypes.object,
};

export default AssetQuestionnaireDialog;
