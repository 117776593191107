import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { FailIcon } from '../icons';
import AlertDialog from './AlertDialog';

const Failure = ({ message, title, ...props }) => (
  <AlertDialog
    title={title}
    content={
      <>
        <Box p={4} align='center'>
          <FailIcon style={{ fontSize: 96 }} />
        </Box>
        <Box p={3} align='center'>
          <Typography>{message}</Typography>
        </Box>
      </>
    }
    variant='failure'
    {...props}
  />
);

export default Failure;
