import { ofType } from 'redux-observable';
import { of, map, mergeMap, filter, catchError } from 'rxjs';
import { SESSION_FETCHED } from './session';
import { Map } from 'immutable';
import { ProfilesApiService } from '../services';

// action types
//
export const PROFILES_FETCHED = 'PROFILES:FETCHED';
export const PROFILES_FETCH_ERROR = 'PROFILES:FETCH_ERROR';
export const PROFILES_DECREMENT_UNREAD = 'PROFILES:DECREMENT_UNREAD';

// action creators
//
export const PROFILES_FETCHED_ = (profiles) => ({
  type: PROFILES_FETCHED,
  profiles,
});
export const PROFILES_FETCH_ERROR_ = (status, message) => ({
  type: PROFILES_FETCH_ERROR,
  status,
  message,
});
export const PROFILES_DECREMENT_UNREAD_ = (profileId) => ({
  type: PROFILES_DECREMENT_UNREAD,
  profileId,
});

export const reduce = (state = Map(), action) => {
  switch (action.type) {
    default:
      return state;
    case PROFILES_FETCHED:
      return action.profiles.reduce((acc, p) => acc.set(p.id, p), state);
    case PROFILES_DECREMENT_UNREAD:
      return state.updateIn(
        [action.profileId, 'unread_notification_count'],
        (p) => (p > 0 ? p - 1 : 0)
      );
  }
};

export const epics = [
  // Fetch profiles.
  //
  (action$, state$) =>
    action$.pipe(
      ofType(SESSION_FETCHED),
      filter((action) => action.session.isAuthorized),
      mergeMap(() =>
        new ProfilesApiService(state$.value.session.access)
          .getAll$()
          .pipe(map((response) => PROFILES_FETCHED_(response)))
      ),
      catchError((error) =>
        of(PROFILES_FETCH_ERROR_(error.status, error.message))
      )
    ),
];
