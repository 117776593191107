import React from 'react';
import { Grid } from '@material-ui/core';
import CenteredGrid from './CenteredGrid';

function CenteredColumn({ children, ...props }) {
  return (
    <CenteredGrid direction='column' spacing={0} {...props}>
      <Grid item xs={12} sm={12} md={11} lg={9}>
        {children}
      </Grid>
    </CenteredGrid>
  );
}

export default CenteredColumn;
