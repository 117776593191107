import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '../hooks';
import { Typography } from '@material-ui/core';
import useManifest from '../hooks/useManifest';

const StoneContext = createContext();

const StoneProvider = ({ children }) => {
  const translate = useIntl();
  const manifest = useManifest();
  const branded = {
    ...translate,
    _brand: manifest ? manifest.brand : undefined
  }

  return (
    <StoneContext.Provider value={branded}>{children}</StoneContext.Provider>
  );
};

const flatSplit = (paths) =>
  (typeof paths === 'string' ? [paths] : paths)
    .map((x) => x.split('.'))
    .flat()
    .filter((x) => !!x);

const maybe = (words, translate, Component, value, {prefix = '', suffix = '', ...props}) => {
  const localized = words.reduce((acc, word) => {
    const step = acc[word];
    if (step === undefined) {
      throw word;
    }
    return step;
  }, translate);
  /* eslint-disable no-eval */
  return (
    <Component {...props}>
      {prefix}
      {value === undefined ? localized : eval('`' + localized + '`')}
      {suffix}
    </Component>
  );
  /* eslint-enable no-eval */
}

// Since value is in this function's scope, if defined, it will expand this:
//   "Here is my ${value}."
// or this:
//   "Here is a ${value.prop}."
//
const Stone = ({
  component = null,
  tag = null,
  children,
  value = null,
  ...props
}) => {
  const path = tag ?? children;
  const translate = useContext(StoneContext);
  const split_words = flatSplit(path);
  const words = translate._brand ? [translate._brand, ...split_words] : split_words;
  const Component = component ?? Typography;
  if (translate) {
    try {
      return maybe(words, translate, Component, value, props);
    }
    catch (word) {
      try {
        return maybe(split_words, translate, Component, value, props);
      }
      catch (_word) {
        console.info(`Stone tag ${tag} missing for text ${children}`);
        // hint where the Rosetta Key's tag was not found
        return (
          <Component {...props}>
            {children}
          </Component>
        );
      }
    }
  }
  else {
    // race condition, no translation loaded yet
    return (
      <Component {...props}>
        {words.join('.')} {value ? JSON.stringify(value) : null}
      </Component>
    );
  }
};

StoneProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

Stone.Context = StoneContext;
Stone.Provider = StoneProvider;
Stone.Consumer = StoneContext.Consumer;

export default Stone;
