import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../components/utils/notistack_redux/redux/actions';

export default function useSnack() {
  const dispatch = useDispatch();
  const onSnack = useCallback((variant, message) => dispatch(enqueueSnackbar({
    message,
    options: { variant },
  })), [
    dispatch
  ]);

  return onSnack;
}
