import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query PromotionsQuery($language: String!) {
    promos: allSecuritiesAssets(condition: { isPromoted: true }) {
      nodes {
        code
        classcontent(languageCode: $language) {
          nodes {
            code
            contentName
            tag
            text
          }
        }
        image: filerImageByFeaturedArtId {
          file: filerFileByFilePtrId {
            path: file
          }
        }
        property: securitiesPropertyassetByAssetPtrId {
          faceValue
          faceValueFiat: securitiesFiatassetByFaceValueQuoteAssetId {
            external: securitiesExternalassetByExternalassetPtrId {
              asset: securitiesAssetByAssetPtrId {
                code
                symbol
              }
            }
          }
        }
      }
    }
  }
`;
