import React from 'react'
import ProfileAvatar from './ProfileAvatar'
import { Card, Box, Typography } from '@material-ui/core'
import ProfileCredentials from './ProfileCredentials'
import { Link } from 'react-router-dom';

export default ({ profile, credentials, style, ...props }) => (
  <Link to={`/@${profile.name}`} style={{ textDecoration: 'none', ...style }} {...props}>
  <Card >
    <Box p={1} display="flex" alignItems="center">
      <Box p={1}>
        <ProfileAvatar profile={profile}/>
      </Box>
      <Box p={1}>
        <b>{profile.displayName}</b><br/>
        @{profile.name}
      </Box>
      {
        profile.group?.nodes[0]?.visibility === 'private'
        &&
        <Box p={1}>
          <Typography variant="caption">PRIVATE</Typography>
        </Box>
      }
      {
        credentials && <ProfileCredentials profile={profile} flexGrow="1"/>
      }
    </Box>
  </Card>
  </Link>
)