import React from 'react';
import { Avatar } from '@material-ui/core';

const ProfileAvatar = ({ profile, ...props }) => {
  const firstLetter = profile.name[0].toUpperCase();
  let photo = profile.photo;
  if (photo && !photo.startsWith('/media/') && !photo.startsWith('http')) {
    photo = '/media/' + photo;
  }
  return (
    <Avatar src={photo} {...props}>
      {firstLetter}
    </Avatar>
  );
};

export default ProfileAvatar;
