import { BaseApiService } from './BaseApiService';

class SessionApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/session/', access, onError);
  }

  create$ = (profileId) => {
    return this.http.postAjax(``, { profileId });
  };

  create = (profileId) => {
    return this.http.postFetch(``, { profileId });
  };

  logout$ = () => {
    return this.http.getAjax('logout');
  };

  logout = () => {
    return this.http.getFetch('logout');
  };
}

export default SessionApiService;
