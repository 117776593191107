import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  LinearProgress,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { post_text } from '../../utils/utils';

const AddPayPalDialog = (ownProps) => {
  const {
    asset,
    t_,
    onClose,
    onError,
    onSubmit,
    profileId,
    ...props
  } = ownProps;
  const [email, setEmail] = React.useState('');
  const [isSubmitting, setSubmitting] = React.useState(false);

  const handleSubmit = () => {
    setSubmitting(true);
    post_text('/loci/link/', {
      profile_id: profileId,
      asset: asset.code,
      institution: 'PayPal',
      accountType: 'paypal',
      accountNumberType: 'paypal',
      accountNumber: email,
      email: email,
      nickname: email,
    })
      .then((response) =>
        response.ok
          ? response.json()
          : {
              success: false,
              error: response.status,
            }
      )
      .then((response) => {
        setSubmitting(false);
        response.error && onError(response.error);
        response.success && onSubmit(response.link_id);
      });
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{t_.addpaypaldestinationdialog.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t_.addpaypaldestinationdialog.description}
        </DialogContentText>
        <TextField
          autoFocus
          margin='dense'
          id='name'
          label='Email'
          type='email'
          fullWidth
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        {isSubmitting && <LinearProgress />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {t_.cancel}
        </Button>
        <Button
          onClick={(event) => handleSubmit(event.target.value)}
          color='primary'
        >
          {t_.add}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  profileId: state.session.profileID,
  t_: state.view.translate.burn,
});

export default connect(mapStateToProps)(AddPayPalDialog);
