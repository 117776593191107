import React from 'react';
import {
  Box,
  Button,
  Card,
  IconButton,
  LinearProgress,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { AssetImage, NumberFormat } from '../components';
import { useAssetContent, useGetOfferInfo, useIntl } from '../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 960,
  },
  image: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  library: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  centered: {
    textAlign: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

function Derivative({ asset, onClick }) {
  const classes = useStyles();

  const translate = useIntl();
  const [total_offer_size, subs, remaining] = useGetOfferInfo(asset);
  const assetContent = useAssetContent(asset.code);

  return (
    <Box mt={1} onClick={onClick}>
      <Card>
        <Box p={1} display='flex' flexDirection='row'>
          <Box p={1} alignSelf='center'>
            <IconButton className={classes.icon} size='small'>
              <AssetImage
                alt={asset.code}
                src={
                  asset.icon[0] === '/' ? asset.icon : `/media/${asset.icon}`
                }
                useStatic={asset.use_static_icon}
                />
            </IconButton>
          </Box>
          <Box
            p={1}
            alignSelf='center'
            flexGrow='1'
            display='flex'
            flexDirection='column'
          >
            {assetContent?.name && (
              <Box pt={1} alignSelf='center'>
                <Typography variant='h5'>{assetContent.name}</Typography>
              </Box>
            )}
            <Box pb={1} alignSelf='center'>
              <Typography variant='caption'>{asset.code}</Typography>
            </Box>
            {!!total_offer_size && ( // hackish way to only show progress and button when there are open offers
              <>
                <Box alignSelf='center'>
                  <Typography>
                    $
                    <NumberFormat
                      value={remaining}
                      displayType='text'
                      isNumericString={true}
                    />{' '}
                    {translate.views.asset.remaining}
                  </Typography>
                </Box>
                <LinearProgress
                  variant='determinate'
                  value={(100 * subs) / total_offer_size}
                  className={classes.progress}
                />
                <Box display='flex' flexDirection='row'>
                  <Box flexGrow='1'>
                    <Typography color='textSecondary'>
                      $
                      <NumberFormat
                        value={subs}
                        displayType='text'
                        isNumericString={true}
                      />{' '}
                      {translate.views.asset.originated}
                    </Typography>
                  </Box>
                  <Typography color='textSecondary' align='right'>
                    $
                    <NumberFormat
                      value={total_offer_size}
                      displayType='text'
                      isNumericString={true}
                    />{' '}
                    {translate.views.asset.total}
                  </Typography>
                </Box>
                <Box p={1} alignSelf='center'>
                  <Button variant='contained' color='primary'>
                    {translate.views.asset.originate}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default Derivative;
