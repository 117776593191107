import React from 'react';
import { Typography, LinearProgress, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { lociColors } from '../components/cube/CubeColors';

const defaultColorMap = {
  undefined: lociColors[7],
  'default': lociColors[7], // grey
  'red': lociColors[3],
  'yellow': lociColors[2],
  'green': lociColors[1],
  'blue': lociColors[0],
};

const useStyles = makeStyles({
  root: {
    height: 10,
    borderRadius: 2,
  },
  colorPrimary: {
    backgroundColor: grey[200],
  },
  bar: {
    backgroundColor: props => props.barColor,
  },
  text: {
    color: props => props.barColor,
  },
});

const LinearStatus = ({ tier, colors=defaultColorMap, ...other }) => { 
  const classes = useStyles({barColor: colors[tier] ?? defaultColorMap[undefined]});

  return (
  <LinearProgress
    variant='determinate'
    classes={{
      root: classes.root,
      colorPrimary: classes.colorPrimary,
      bar: classes.bar,
    }}
    {...other}
  />
)};

export const StatusText = 
  ({ tier, colors=defaultColorMap, children, ...other }) => { 
    const classes = useStyles({barColor: colors[tier] ?? defaultColorMap[undefined]});
    return (
    <Typography className={classes.text} {...other}>
      {children}
    </Typography>
  )}
;

export default LinearStatus;
