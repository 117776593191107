import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useWallet(assetCode) {
    const [wallet, setWallet] = useState(null);
    const wallets = useSelector(state => state.wallets);
    useEffect(() => {
        setWallet(wallets.get(assetCode))
    }, [assetCode, wallets]);
    return wallet;
}
