import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import {
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Tooltip,
  Typography,
  isWidthDown,
  makeStyles,
} from '@material-ui/core';
import {
  Cancel as CancelIcon,
  Done as DoneIcon,
  FilterList as FilterListIcon,
} from '@material-ui/icons';
import { ORDERS_BY_FILTER_ } from '../state/orders';
import {
  FILTER_BY_OPEN,
  FILTER_BY_ACTIVE,
  FILTER_BY_PAST_24_HOURS,
  FILTER_BY_DATETIME,
  FILTER_SHOW_DATETIME,
  FILTER_ITEM_SELECT_,
} from '../state/filterMenu';
import FilterChip from './FilterChip';
import FilterDateDialog from './FilterDateDialog';
import { useWidth } from '../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.text.primary,
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    zIndex: 100,
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    width: 200,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sm_button: {
    width: 120,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  inline_container: {
    display: 'inline-flex',
  },
  middle: {
    alignSelf: 'center',
  },
  icon: {},
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const OrdersToolbar = ({ selected, cancelOrders }) => {
  const classes = useStyles();
  const width = useWidth();
  
  const ordersFilters = useSelector((state) => state.filterMenus.OrdersFilters);
  const market = useSelector((state) => state.view.market);
  const timeRange = useSelector((state) => state.timeRanges.TimeRange);

  const dispatch = useDispatch();
  const selectItem = useCallback(
    (id) => dispatch(FILTER_ITEM_SELECT_(id)),
    [dispatch]
  );
  const ordersByFilter = useCallback(
    (queryParams) => dispatch(ORDERS_BY_FILTER_(queryParams)),
    [dispatch]
  );

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const handleToggle = (placement) => (event) => {
    const { currentTarget } = event;
    setOpen((p) => !p);
    setAnchorEl(currentTarget);
  };

  // eslint-disable-next-line no-unused-vars
  const handleClose = (event) => {
    setOpen(false);
  };

  const getQueryParams = (ordersFilters) => {
    let index = 0;
    let queryParams = {};

    ordersFilters.forEach((filter) => {
      // eslint-disable-next-line default-case
      switch (filter.id) {
        case FILTER_BY_OPEN:
          if (filter.selected) {
            queryParams = { ...queryParams, open: 'yes' }
          }
          break;
        case FILTER_BY_ACTIVE:
          if (filter.selected) {
            queryParams = { ...queryParams, market: market }
          }
          break;
        case FILTER_BY_PAST_24_HOURS:
          if (filter.selected) {
            queryParams = { ...queryParams, today: 'yes' }
          }
          break;
        case FILTER_BY_DATETIME:
          if (filter.selected) {
            /* eslint-disable eqeqeq */
            timeRange[0].time =
              timeRange[0].time != ''
                ? timeRange[0].time
                : new Date().toISOString();
            timeRange[1].time =
              timeRange[1].time != ''
                ? timeRange[1].time
                : new Date().toISOString();
            /* eslint-enable eqeqeq */
            queryParams = { ...queryParams, begin: timeRange[0].time, end: timeRange[1].time }
          }
          break;
      }

      index++;
      if (index === ordersFilters.length) {
        ordersByFilter(queryParams);
      }
    });
  };

  const handleSelect = (event, ordersFilters) => {
    selectItem(event.target.value);
    getQueryParams(ordersFilters);
    setOpen(false);
  };

  const checkDateFilterSelected = (orderProps, id) => {
    return orderProps.filter((x) => x.id === id)[0].selected;
  };

  const checkNeedShowFilterChips = (orderProps) => {
    return orderProps.some((x) => x.selected === true);
  };

  const okDateRangehandler = () => {
    selectItem(FILTER_SHOW_DATETIME);
    selectItem(FILTER_BY_DATETIME);
    getQueryParams(ordersFilters);
  };

  const cancelDateRangeHandler = () => {
    selectItem(FILTER_SHOW_DATETIME);
  };

  var customOrdersFilters = ordersFilters.slice(0, 4);

  let orderText = selected.length > 1 ? ' Orders' : ' Order';
  let buttonText = isWidthDown('sm', width)
    ? 'Cancel'
    : 'Cancel ' + selected.length + orderText;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: selected.length > 0,
      })}
    >
      <div className={classes.title}>
        {selected.length > 0 ? (
          <Typography color='inherit' variant='subtitle1'>
            {selected.length} selected
          </Typography>
        ) : (
          ''
        )}
      </div>
      <div className={classes.spacer} />
      {checkDateFilterSelected(ordersFilters, FILTER_SHOW_DATETIME) ? (
        <FilterDateDialog
          open={checkDateFilterSelected(ordersFilters, FILTER_SHOW_DATETIME)}
          okDateRangehandler={okDateRangehandler}
          cancelDateRangeHandler={cancelDateRangeHandler}
        />
      ) : (
        ''
      )}
      {selected.length > 0 ? (
        ''
      ) : checkNeedShowFilterChips(ordersFilters) ? (
        <div>
          {' '}
          <FilterChip />{' '}
        </div>
      ) : (
        ''
      )}
      <div className={classes.actions}>
        {selected.length > 0 ? (
          <div className={classes.inline_container}>
            <div className={classes.middle}>
              <Button
                variant='contained'
                className={
                  isWidthDown('sm', width) ? classes.sm_button : classes.button
                }
                onClick={cancelOrders}
              >
                <CancelIcon className={classes.leftIcon} />
                {buttonText}
              </Button>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <Tooltip title='Filter list'>
              <IconButton
                aria-label='Filter list'
                onClick={handleToggle('bottom-end')}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>

            <Popper
              open={open}
              anchorEl={anchorEl}
              transition
              disablePortal={true}
              placement='bottom-end'
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id='menu-list-grow'
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper placement='bottom-end'>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList>
                        {customOrdersFilters.map((item) => {
                          return (
                            <MenuItem
                              key={item.id}
                              onClick={(e) => {
                                handleSelect(e, ordersFilters);
                              }}
                              value={item.id}
                            >
                              {item.title}
                              {item.selected ? (
                                <ListItemIcon className={classes.icon}>
                                  <DoneIcon />
                                </ListItemIcon>
                              ) : (
                                ''
                              )}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment>
        )}
      </div>
    </Toolbar>
  );
};

OrdersToolbar.propTypes = {
  selected: PropTypes.array.isRequired,
  cancelOrders: PropTypes.func.isRequired,
};

export default OrdersToolbar;
