import React from 'react';
import { SvgIcon } from '@material-ui/core';

// based on core/static/img/logos/swirl.svg
//   based on core/static/img/logos/white-swirl.png
//
export default props => (
  <SvgIcon viewBox="0 0 240 240" {...props}>
    <g transform="translate(0.000000,240.000000) scale(0.100000,-0.100000)">
      <path d="M985 2360 c-293 -63 -555 -236 -728 -481 -65 -92 -141 -255 -174
      -374 -26 -94 -27 -112 -27 -305 0 -191 2 -212 27 -303 31 -118 107 -279 176
      -380 69 -100 217 -242 324 -312 119 -77 277 -145 277 -119 0 3 -25 34 -56 67
      -291 320 -360 770 -178 1156 125 264 341 449 612 523 61 17 106 21 217 21 123
      0 150 -3 228 -26 91 -28 179 -68 210 -95 15 -13 17 -34 17 -159 0 -165 -1
      -166 -78 -108 -61 47 -172 101 -247 120 -32 8 -95 15 -140 15 -174 0 -302 -56
      -436 -190 -72 -71 -94 -101 -133 -180 -67 -137 -81 -199 -81 -360 0 -114 4
      -147 23 -210 64 -203 197 -362 377 -451 113 -55 189 -72 320 -72 139 1 234 22
      350 78 258 125 440 402 500 763 26 152 17 400 -18 535 -57 217 -151 380 -307
      537 -155 154 -310 243 -527 302 -115 31 -401 36 -528 8z"/>
    </g>
  </SvgIcon>
)