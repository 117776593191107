import { BaseApiService } from './BaseApiService';

class OrdersApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/orders/', access, onError);
  }

  getFiltered$ = (queryParams) => {
    return this.http.getAjax(``, queryParams);
  }

  getFiltered = (queryParams) => {
    return this.http.getFetch(``, queryParams);
  }
}

export default OrdersApiService;
