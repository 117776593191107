import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Stone } from '..';
import { OrgIcon, UserIcon } from '../../icons';

const ProfileIcon = ({ profile }) => {
  const color =
    profile.profileCompleteDate === null
      ? { icon: 'disabled', text: 'textSecondary' }
      : { icon: 'secondary', text: 'secondary' };
  return (
    <Box>
      <Grid container direction='column' alignItems='center'>
        {profile.profileType === 'PERSONAL' ||
        profile.organizationType === 'personal' ? (
          <>
            <UserIcon color={color.icon} fontSize='large' />
            <div />
            <Stone variant='caption' color={color.text}>
              profile.personal_profile
            </Stone>
          </>
        ) : (
          <>
            <OrgIcon color={color.icon} fontSize='large' />
            <div />
            <Stone variant='caption' align='center' color={color.text}>
              profile.organizational_profile
            </Stone>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ProfileIcon;
