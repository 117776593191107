import React from 'react';
import { SvgIcon } from '@material-ui/core';

//  <SvgIcon version="1.0" style={{width:35, height:35}} viewBox="0 0 280 280" preserveAspectRatio="xMidYMid meet" x="0px" y="0px">
//    <g transform="translate(-50,400.0) scale(0.165,-0.2)" stroke="none">
export default () => (
  <SvgIcon
    version='1.0'
    style={{ width: 35, height: 35 }}
    viewBox='-2000 -2000 1650 1400'
    preserveAspectRatio='xMidYMid meet'
    x='0px'
    y='0px'
  >
    <g transform='scale(-1,-1)' stroke='none'>
      <path d='M715 1811 c-69 -30 -94 -124 -46 -175 36 -38 82 -50 123 -33 83 35 98 130 31 190 -24 21 -80 30 -108 18z' />
      <path d='M396 1784 c-27 -27 -18 -49 54 -130 204 -230 280 -329 308 -396 28 -71 51 -86 77 -51 9 11 28 17 61 18 27 1 59 5 71 9 26 8 67 82 58 105 -13 34 -22 101 -13 101 15 0 68 -43 68 -55 0 -6 -16 -41 -36 -78 -36 -69 -38 -92 -8 -122 9 -8 11 -15 6 -15 -5 0 -15 7 -22 15 -7 8 -18 15 -26 15 -23 0 -64 -23 -64 -36 0 -17 -61 -37 -74 -24 -5 5 -21 10 -34 10 -20 0 -23 -4 -19 -21 3 -12 -9 -63 -29 -118 -23 -62 -33 -105 -29 -121 8 -31 161 -233 183 -241 25 -9 59 7 67 31 5 15 -10 49 -55 126 l-61 105 38 66 c21 37 43 68 50 70 6 2 52 -76 107 -186 98 -194 114 -214 155 -192 39 21 34 45 -58 281 l-89 230 52 79 c76 113 84 136 62 175 -17 31 -90 95 -169 149 -58 39 -120 38 -163 -4 -17 -17 -48 -34 -68 -39 -71 -16 -101 2 -234 136 -67 68 -129 124 -137 124 -7 0 -21 -7 -29 -16z' />
      <path d='M1094 1585 c125 -97 156 -132 156 -178 0 -31 -35 -104 -86 -181 l-34 -51 30 -79 c16 -44 35 -83 42 -88 77 -52 210 -93 259 -81 23 6 24 13 4 39 -18 24 -19 58 -2 71 23 19 82 33 204 48 116 14 173 29 173 45 0 4 -7 13 -15 20 -23 19 -18 27 23 41 20 6 58 22 84 36 69 34 63 65 -21 108 -29 14 -855 295 -869 295 -3 0 20 -20 52 -45z' />
    </g>
  </SvgIcon>
);
