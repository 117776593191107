import { ofType } from 'redux-observable';
import { of, map, mergeMap, catchError } from 'rxjs';
import { Map } from 'immutable';
import { FriendsApiService } from '../services';

// action types
//
export const FRIENDS_FETCH = 'FRIENDS:FETCH';
export const FRIENDS_FETCHED = 'FRIENDS:FETCHED';
export const FRIENDS_FETCH_ERROR = 'FRIENDS:FETCH_ERROR';

// action creators
//
export const FRIENDS_FETCH_ = () => ({ type: FRIENDS_FETCH });
export const FRIENDS_FETCHED_ = (friends) => ({
  type: FRIENDS_FETCHED,
  friends,
});
export const FRIENDS_FETCH_ERROR_ = (status, message) => ({
  type: FRIENDS_FETCH_ERROR,
  status,
  message,
});

export const reduce = (state = Map(), action) => {
  switch (action.type) {
    default:
      return state;
    case FRIENDS_FETCHED:
      return action.friends.reduce(
        (acc, f) => acc.set(f.friend.name, f.friend),
        state
      );
  }
};

export const epics = [
  // Fetch profiles.
  //
  (action$) =>
    action$.pipe(
      ofType(FRIENDS_FETCH),
      // eslint-disable-next-line no-unused-vars
      mergeMap((action) =>
        new FriendsApiService()
          .getAll$()
          .pipe(map((response) => FRIENDS_FETCHED_(response)))
      ),
      catchError((error) =>
        of(FRIENDS_FETCH_ERROR_(error.status, error.message))
      )
    ),
];
