import graphql from 'babel-plugin-relay/macro';

export default graphql`
  fragment AssetPreviewFragment_asset on SecuritiesAsset @argumentDefinitions(
    language: { type: "String" }
  ) {
    code
    icon
    name
    isOfferGroup
    isQuestionnaireForDerivatives
    image: filerImageByFeaturedArtId {
      file: filerFileByFilePtrId {
        path: file
      }
    }
    images: securitiesAssetimagesByAssetId {
      nodes {
        image: filerImageByImageId {
          file: filerFileByFilePtrId {
            path: file
          }
        }
      }
    }
    property: securitiesPropertyassetByAssetPtrId {
      project
      noteDate
      faceValue
      faceValueFiat: securitiesFiatassetByFaceValueQuoteAssetId {
        external: securitiesExternalassetByExternalassetPtrId {
          asset: securitiesAssetByAssetPtrId {
            code
            symbol
          }
        }
      }
      grantor: securitiesProfileByGrantorId {
        name
        displayName
      }
      manager: securitiesProfileByPropertyManagerId {
        name
        displayName
      }
    }
    classcontent(languageCode: $language) {
      nodes {
        code
        contentName
        tag
        text
      }
    }
    derivatives: securitiesAssetsByUnderlyingId(
      condition: { isActive: true, visible: true, visibleInvest: true }
    ) {
      nodes {
        code
        icon
        sortTerm
        symbol
        ctype: djangoContentTypeByPolymorphicCtypeId {
          model
        }
        networkAsset: securitiesNetworkassetByAssetPtrId {
          securityAsset: securitiesSecurityassetByNetworkassetPtrId {
            noteAsset: securitiesNoteassetBySecurityassetPtrId {
              coupon
            }
          }
        }
        classcontent(languageCode: $language) {
          nodes {
            code
            contentName
            tag
            text
          }
        }
      }
    }
    underlying: securitiesAssetByUnderlyingId {
      code
      name
      isOfferGroup
      isQuestionnaireForDerivatives
      classcontent(languageCode: $language) {
        nodes {
          code
          contentName
          tag
          text
        }
      }
    }
  }
`;
