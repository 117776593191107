import * as Yup from 'yup';

const Schema = {
  perk_decimal_fee: Yup
    .number()
    .nullable()
    .positive()
    .min(0.00001)
    .max(99999999.9999)
    ,
};

export default Schema;
