import { BaseApiService } from './BaseApiService';

class UserPrefApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/user/preferences/', access, onError);
  }
  put$ = (body) => {
    return this.http.putAjax('', body);
  };

  put = async (body) => {
    return this.http.putFetch('', body);
  };

  patch$ = (body) => {
    return this.http.patchAjax('', body);
  };

  patch = (body) => {
    return this.http.patchFetch('', body);
  };
}

export default UserPrefApiService;
