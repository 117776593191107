import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { marketprops } from './marketprops'
import { markets } from './markets'
import { reduce as mqtt } from './mqtt'
import { view } from './view'
import { orders } from './orders'
import { reduce as fills } from './fills'
import { filterMenus, timeRanges } from './filterMenu'
import { reduce as profiles } from './profiles'
import { reduce as friends } from './friends'
import { reduce as wallets } from './wallets'
import { reduce as md } from './md'
import { reduce as session } from './session'
import { reduce as assets } from './assets'
import { reduce as links } from './links'
import { reduce as relay } from './relay'
import { audience } from './audience'

import notifications from "../components/utils/notistack_redux/redux/reducers.js"

export default history => combineReducers({
  router: connectRouter(history),
  session,
  relay,
  view,
  assets,
  marketprops,
  markets,
  md,
  mqtt,
  orders,
  fills,
  profiles,
  friends,
  wallets,
  filterMenus,
  timeRanges,
  links,
  notifications,
  audience
})
