import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import InvestorIcon from '../../icons/InvestorIcon';
import { useLink, useIntl } from '../../hooks';

const QualifiedInvestorIcon = ({ profile }) => {
  const translate = useIntl();
  const own = useSelector(
    (state) => state.session.profile.id === profile.rowId
  );
  const color =
    profile.wealthVerified === 'approved'
      ? { icon: 'secondary', text: 'secondary' }
      : { icon: 'disabled', text: 'textSecondary' };
  const pushLink = useLink();

  return (
    <Box>
      <Grid container direction='column' alignItems='center'>
        {own && profile.wealthVerified !== 'approved' ? (
          <>
            <Button
              disabled={false && profile.identityVerified !== 'approved'}
              variant='outlined'
              onClick={() => pushLink('/legacy/verify_wealth')}
              color='primary'
              startIcon={<InvestorIcon />}
            >
              {translate.profile.button_wealth_verification}
            </Button>
          </>
        ) : (
          <>
            <InvestorIcon color={color.icon} fontSize='large' />
            <div />
            <Typography variant='caption' align='center' color={color.text}>
              {translate.profile.qualified_investor}
            </Typography>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default QualifiedInvestorIcon;
