import React from 'react';
import {
  ArrowRightIcon,
  CheckBoxBlankIcon,
  CheckCircleIcon as CheckBoxCheckIcon,
} from '../icons';
import {
  Box,
  Checkbox,
  Typography,
  GridList,
  GridListTile,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { DocumentLink } from '../components';
import { useIntl, useApiService } from '../hooks';
import { AgreementsApiService } from '../services';

const useStyles = makeStyles(() => ({
  root: {
    padding: '4px 0px 0px 0px',
  },
  tile: {
    margin: '2px 0px 2px 0px',
  },
}));

const AgreementList = ({ onChange, profileId, docs }) => {
  const classes = useStyles();
  const translate = useIntl();
  const agreementsApi = useApiService(AgreementsApiService);

  const handleConfirm = (doc) => {
    agreementsApi
      .post({
        doc_id: doc.rowId,
        profile_id: profileId,
      })
      .then((response) => {
        if (response.ok) {
          onChange();
        }
      });
  };

  const cue = docs.findIndex((doc) => !doc.doc.agreement.isConfirmed);

  return (
    <>
      <Typography variant='h5'>{translate.views.asset.documents}</Typography>
      {cue > -1 && (
        <Typography paragraph color='primary'>
          {translate.views.asset.documents_desc}
        </Typography>
      )}
      <Divider />
      <GridList className={classes.root} cellHeight='auto' cols={1}>
        {docs.map((doc, index) => {
          const checked = doc.doc.agreement.isConfirmed
            ? { checked: true, contentEditable: false }
            : {};
          return (
            <GridListTile className={classes.tile} key={index}>
              <Box display='flex' alignItems='center'>
                <DocumentLink
                  flexGrow='1'
                  key={index}
                  href={`/media/${doc.doc.file}`}
                  caption={translate._document_role[doc.doc.role]}
                />
                {index === cue && (
                  <>
                    <Typography variant='caption' color='primary'>
                      tap to accept
                    </Typography>
                    <ArrowRightIcon fontSize='large' color='primary' />
                  </>
                )}
                <Checkbox
                  {...checked}
                  icon={<CheckBoxBlankIcon />}
                  checkedIcon={<CheckBoxCheckIcon />}
                  onClick={() =>
                    doc.doc.agreement.isConfirmed || handleConfirm(doc.doc)
                  }
                />
              </Box>
            </GridListTile>
          );
        })}
      </GridList>
    </>
  );
};

export default AgreementList;
