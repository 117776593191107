import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { UserIcon } from '../icons';
import { CenteredColumn, Stone } from '.';

const AddressNotFound = ({ name }) => {
  return (
    <CenteredColumn>
      <Box p={1} align='center'>
        <UserIcon style={{ fontSize: 96 }} />
      </Box>
      <Box p={1} align='center'>
        <Stone variant='h5'>common.address_not_found</Stone>
      </Box>
      <Box p={1} align='center'>
        <Typography>{name}</Typography>
      </Box>
    </CenteredColumn>
  );
};

export default AddressNotFound;
