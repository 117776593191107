import React from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import selectProxyAndLTAssets from '../selectors/selectProxyAndLTAssets';
import { useAssetClasses, useError, useViewer, useIntl, useApiService } from '../hooks';
import { VenueApiService, VenuesApiService } from '../services';
import FormikAssetField from './fields/FormikAssetField';
import FormikNumberField from './fields/FormikNumberField';
import FormikTextarea from './fields/FormikTextarea';
import memoize from 'async-memo-ize';
import { DatePicker } from 'formik-material-ui-pickers';
import FormikSelectField from './fields/FormikSelectField';
import FormikCheckboxField from './fields/FormikCheckboxField';
import { DateTime } from 'luxon';
import { ResponsiveDialog as Dialog } from '.';

const useStyles = makeStyles((theme) => ({
  control: {
    margin: theme.spacing(2),
  },
}));

const VenueForm = ({ venue, onCancel, onChange, onCreate }) => {
  const translate = useIntl();
  const classes = useStyles();
  const viewer = useViewer();
  const assets = useSelector((state) => selectProxyAndLTAssets(state));
  const isStaff = useSelector((state) => state.session?.user?.isStaff ?? false);
  const assetClasses = useAssetClasses();
  const onError = useError();
  const venuesApi = useApiService(VenuesApiService);
  const venueApi = useApiService(VenueApiService);

  if (!viewer || !assets || !assetClasses) {
    return <div />;
  }

  return (
    <Formik
      initialValues={
        venue
          ? {
              ...venue,
              assetClass: venue.assetClass?.slug ?? 'personal',
              target: venue.target ?? '',
              targetQuoteAsset:
                venue.targetAsset?.code ?? viewer.defaultAssetCode,
              endsAt: venue.endsAt ?? DateTime.now().plus({ weeks: 7 }),
            }
          : {
              name: '',
              venueType: 'fund',
              assetClass: 'personal',
              description: '',
              target: '',
              targetQuoteAsset: viewer.defaultAssetCode,
              isAllOrNothing: false,
              endsAt: DateTime.now().plus({ weeks: 7 }),
            }
      }
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(64)
          .required()
          .test(
            'valid-new-name',
            'Already exists',
            venue === null
              ? memoize(
                  async (name) =>
                    await venuesApi.postSearch({ name })
                      .then((r) => !r.ok || !r.bodyJson.length)
                      // eslint-disable-next-line no-unused-vars
                      .catch((err) => true),
                  { id: 'valid-new-name' }
                )
              : () => true
          ),
        venueType: Yup.string().required(),
        assetClass: Yup.string().required(),
        description: Yup.string().required(),
        target: Yup.number().when('venueType', {
          is: 'fund',
          then: (schema) => schema.required(),
        }),
        targetQuoteAsset: Yup.string().when('venueType', {
          is: 'fund',
          then: (schema) => schema.required(),
        }),
        isAllOrNothing: Yup.boolean().optional(),
        endsAt: Yup.date().optional(),
      })}
      onSubmit={
        // eslint-disable-next-line no-unused-vars
        async ({ link, ...values }, { setSubmitting }) => {
          await (venue === null ? venuesApi.post(values) : venueApi.post(venue.rowId, values))
            .then((response) => {
              if (response.ok) {
                if (venue) {
                  onChange();
                } else {
                  onCreate(response.bodyJson);
                }
              } else {
                onError(response);
              }
              setSubmitting(false);
            })
            .catch((err) => onError(err));
        }
      }
    >
      {
        // eslint-disable-next-line no-unused-vars
        ({ errors, isSubmitting, isValid, submitForm, touched, values }) => {
          return (
            <Form>
              <DialogContent>
                <DialogContentText>
                  {translate.venue.dialog.description}
                </DialogContentText>
                <FormControl className={classes.control}>
                  <Field
                    autoFocus
                    name='name'
                    component={TextField}
                    label={translate.venue.field.name}
                  />
                  <FormHelperText>
                    {translate.venue.field_help.name}
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.control}>
                  <InputLabel htmlFor='venueType'>
                    {translate.venue.field.venueType.label}
                  </InputLabel>
                  <FormikSelectField
                    name='venueType'
                    inputProps={{ id: 'venueType' }}
                  >
                    {{ venue: translate.venue.field.venueType.venue }}
                    {{ fund: translate.venue.field.venueType.fund }}
                  </FormikSelectField>
                  <FormHelperText>
                    {translate.venue.field_help.venueType}
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.control}>
                  <InputLabel htmlFor='assetClass'>
                    {translate.venue.field.class}
                  </InputLabel>
                  <FormikSelectField
                    name='assetClass'
                    inputProps={{ id: 'assetClass' }}
                  >
                    {assetClasses.map((assetClass, index) => (
                      (assetClass.isOpen) && 
                      <MenuItem
                        key={index}
                        disabled={!(isStaff || assetClass.isOpen)}
                        value={assetClass.slug}
                      >
                        {translate?.assetclass[assetClass?.slug]?.name || `assetclass[${assetClass?.slug}].name`}
                      </MenuItem>
                    ))}
                  </FormikSelectField>
                  <FormHelperText>
                    {translate.venue.field_help.class}
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.control}>
                  <Field
                    name='description'
                    component={FormikTextarea}
                    rowsMin={5}
                    style={{ fontFamily: 'Roboto' }}
                    placeholder={translate.venue.field.description}
                  />
                  <FormHelperText>
                    {translate.venue.field_help.description}
                  </FormHelperText>
                </FormControl>
                {values.venueType === 'fund' && (
                  <>
                    <FormControl className={classes.control}>
                      <FormikAssetField
                        label={translate.venue.field.targetQuoteAsset}
                        name='targetQuoteAsset'
                        assets={assets}
                      />
                      <FormHelperText>
                        {translate.venue.field_help.targetQuoteAsset}
                      </FormHelperText>
                    </FormControl>
                    <FormControl className={classes.control}>
                      <FormikNumberField
                        decimalScale={
                          assets.get(values.targetQuoteAsset).decimals_min
                        }
                        label={translate.venue.field.target}
                        name='target'
                      />
                      <FormHelperText>
                        {translate.venue.field_help.target}
                      </FormHelperText>
                    </FormControl>
                    <FormControl className={classes.control}>
                      <Field
                        name='endsAt'
                        component={DatePicker}
                        variant='dialog'
                        label={translate.venue.field.endsAt}
                      />
                      <FormHelperText>
                        {translate.venue.field_help.endsAt}
                      </FormHelperText>
                    </FormControl>
                    <FormControl className={classes.control}>
                      <FormikCheckboxField
                        name='isAllOrNothing'
                        label={translate.venue.field.isAllOrNothing}
                      />
                      <FormHelperText>
                        {translate.venue.field_help.isAllOrNothing}
                      </FormHelperText>
                    </FormControl>
                  </>
                )}
              </DialogContent>
              <br />
              <DialogActions>
                <Button variant='text' onClick={onCancel} color='default'>
                  {translate.dialog.cancel}
                </Button>
                <Button
                  variant='contained'
                  disabled={!touched || !isValid || isSubmitting}
                  onClick={submitForm}
                  color='primary'
                >
                  {translate.dialog.submit}
                </Button>
              </DialogActions>
            </Form>
          );
        }
      }
    </Formik>
  );
};

const VenueDialog = ({ venue, onCancel, onCreate, onChange, open }) => {
  const translate = useIntl();

  return (
    <div>
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle id='form-dialog-title'>
          {translate.venue.dialog.title}
        </DialogTitle>
        <VenueForm
          venue={venue}
          onCreate={onCreate}
          onChange={onChange}
          onCancel={onCancel}
        />
      </Dialog>
    </div>
  );
};

export default VenueDialog;
