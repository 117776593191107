import React from 'react';
import ReactNumberFormat from 'react-number-format';
import { localeNumberSeparators } from '../utils/utils';
import { useLang } from '../hooks';

// eslint-disable-next-line no-unused-vars
const NumberFormat = ({ dispatch, ...props }) => {
  const language = useLang();
  const [thousandSeparator, decimalSeparator] = localeNumberSeparators(
    language
  );

  return (
    <ReactNumberFormat
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      isNumericString={true}
      allowedDecimalSeparators={[decimalSeparator]}
      {...props}
    />
  );
};

export default NumberFormat;
