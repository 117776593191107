import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query useAssetClassContentQuery($assetClassId: Int!, $lang: String = "en") {
    assetClasses: securitiesAssetclassByRowId(rowId: $assetClassId) {
      slug
      classcontent(languageCode: $lang) {
        nodes {
          code
          contentName
          tag
          text
        }
      }
    }
  }
`;
