import React, { forwardRef } from 'react';

import Add from '@material-ui/icons/Add';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Bank from '@material-ui/icons/AccountBalance';
import CheckCircle from '@material-ui/icons/CheckCircle';
import EmojiObjects from '@material-ui/icons/EmojiObjects'
import ExpandMore from '@material-ui/icons/ExpandMore';
import Help from '@material-ui/icons/Help';
import Home from '@material-ui/icons/Home';
import Info from '@material-ui/icons/Info';
import Notifications from '@material-ui/icons/NotificationsOutlined';
import Org from '@material-ui/icons/People';
import Pocket from '@material-ui/icons/AccountBalanceWallet';
import Rewards from '@material-ui/icons/Score';
import Settings from '@material-ui/icons/Settings';
import Store from '@material-ui/icons/Store';
import Success from '@material-ui/icons/CheckCircleOutlineTwoTone';
import User from '@material-ui/icons/AccountCircle';

export { default as ActivityIcon } from '@material-ui/icons/PlaylistAddCheck';
export { default as AddPerkIcon } from '@material-ui/icons/PlaylistAdd';
export { default as AdminIcon } from '@material-ui/icons/SupervisorAccount';
export { default as ArrowRightIcon } from '@material-ui/icons/ArrowRightAlt';
export { default as AspectRatioIcon } from '@material-ui/icons/AspectRatio';
export { default as BarChartIcon } from '@material-ui/icons/BarChart';
export { default as BurnIcon } from '@material-ui/icons/Whatshot';
export { default as CancelIcon } from '@material-ui/icons/Cancel';
export { default as CenterIcon } from '@material-ui/icons/CenterFocusStrong';
export { default as CheckBoxBlankIcon } from '@material-ui/icons/CheckBoxOutlineBlank';
export { default as CheckIcon } from '@material-ui/icons/CheckCircle';
export { default as CloseIcon } from '@material-ui/icons/Close';
export { default as ContactUsIcon } from '@material-ui/icons/ContactSupport';
export { default as DashboardIcon } from '@material-ui/icons/Dashboard';
export { default as DeleteIcon } from '@material-ui/icons/Delete';
export { default as DocumentIcon } from '@material-ui/icons/Description';
export { default as DoneIcon } from '@material-ui/icons/Done';
export { default as DotIcon } from '@material-ui/icons/FiberManualRecord';
export { default as DownloadIcon } from '@material-ui/icons/CloudDownload';
export { default as EmailIcon } from '@material-ui/icons/AlternateEmail';
export { default as ExpandIcon } from '@material-ui/icons/FormatLineSpacing';
export { default as ExploreIcon } from '@material-ui/icons/HomeWork';
export { default as ExpressIcon } from '@material-ui/icons/DoubleArrow';
export { default as FailIcon } from '@material-ui/icons/Error';
export { default as FAQIcon } from '@material-ui/icons/QuestionAnswer';
export { default as FormatAlignLeftIcon } from '@material-ui/icons/FormatAlignLeft';
export { default as FormatAlignCenterIcon } from '@material-ui/icons/FormatAlignCenter';
export { default as FormatAlignRightIcon } from '@material-ui/icons/FormatAlignRight';
export { default as FriendsIcon } from './FriendsIcon';
export { default as GameIcon } from '@material-ui/icons/Gamepad';
export { default as GetIcon } from './MintIcon';
export { default as LineChartIcon } from '@material-ui/icons/ShowChart';
export { default as LinkIcon } from '@material-ui/icons/Link';
export { default as LocalIcon } from '@material-ui/icons/LocalAtm';
export { default as LockIcon } from '@material-ui/icons/Lock';
export { default as LogoIcon } from './LogoIcon';
export { default as LogoutIcon } from '@material-ui/icons/PowerSettingsNew';
export { default as MoreIcon } from '@material-ui/icons/MoreHoriz';
export { default as MoreVertIcon } from '@material-ui/icons/MoreVert';
export { default as MAXIcon } from '@material-ui/icons/ShowChart';
export { default as OpenInNewIcon } from '@material-ui/icons/OpenInNew';
export { default as PackIcon } from '@material-ui/icons/Reorder';
export { default as PaymentsIcon } from './PaymentsIcon';
export { default as PersonIcon } from '@material-ui/icons/Person';
export { default as PersonAddIcon } from '@material-ui/icons/PersonAdd';
export { default as PieChartIcon } from '@material-ui/icons/PieChart';
export { default as PolicyIcon } from '@material-ui/icons/Policy';
export { default as PortelIcon } from './PortelIcon';
export { default as PrivateIcon } from './HandshakeIcon';
export { default as RemoveIcon } from '@material-ui/icons/Remove';
export { default as ReportIcon } from '@material-ui/icons/Assessment';
export { default as RequestIcon } from '@material-ui/icons/RecordVoiceOver';
export { default as SecurityIcon } from '@material-ui/icons/Security';
export { default as SendIcon } from '@material-ui/icons/AccountCircle';
export { default as SoukIcon } from '@material-ui/icons/Chat';
export { default as StreetIcon } from '@material-ui/icons/Storefront';
export { default as SuperIcon } from './SuperIcon';
export { default as TableChartIcon } from '@material-ui/icons/TableChart';
export { default as TradeIcon } from '@material-ui/icons/CompareArrows';
export { default as TransferIcon } from '@material-ui/icons/Send';
export { default as ToolsIcon } from '@material-ui/icons/Code';
export { default as UploadIcon } from '@material-ui/icons/CloudUpload';
export { default as VisitIcon } from './VisitIcon';
export { default as VerifiedUserIcon } from '@material-ui/icons/VerifiedUser';

// The forwardRef wrappers allow these icons to be used flexibly without
// having to specially wrap them in <SvgIcon/> components

export const AddIcon = forwardRef((props, ref) => <Add {...props} ref={ref} />);

export const AddCircleOutlineIcon = forwardRef((props, ref) => (
  <AddCircleOutline {...props} ref={ref} />
));

export const BankIcon = forwardRef((props, ref) => (
  <Bank {...props} ref={ref} />
));

export const CheckCircleIcon = forwardRef((props, ref) => (
  <CheckCircle {...props} ref={ref} />
));

export const ExpandMoreIcon = forwardRef((props, ref) => (
  <ExpandMore {...props} ref={ref} />
));

export const HelpIcon = forwardRef((props, ref) => (
  <Help {...props} ref={ref} />
));

export const HomeIcon = forwardRef((props, ref) => (
  <Home {...props} ref={ref} />
));

export const IdeaIcon = forwardRef((props, ref) => (
  <EmojiObjects {...props} ref={ref} />
));

export const InfoIcon = forwardRef((props, ref) => (
  <Info {...props} ref={ref} />
));

export const NotificationsIcon = forwardRef((props, ref) => (
  <Notifications {...props} ref={ref} />
));

export const OrgIcon = forwardRef((props, ref) => <Org {...props} ref={ref} />);

export const PocketIcon = forwardRef((props, ref) => (
  <Pocket {...props} ref={ref} />
));  

export const RewardsIcon = forwardRef((props, ref) => (
  <Rewards {...props} ref={ref} />
));

export const SettingsIcon = forwardRef((props, ref) => (
  <Settings {...props} ref={ref} />
));

export const StoreIcon = forwardRef((props, ref) => (
  <Store {...props} ref={ref} />
));

export const SuccessIcon = forwardRef((props, ref) => (
  <Success {...props} ref={ref} />
));

export const UserIcon = forwardRef((props, ref) => (
  <User {...props} ref={ref} />
));
