export default function useGetOfferInfo(asset) {
  let total = 0;
  let subs = 0;

  const issues = asset.issues.nodes;
  
  for (let i in issues) {
    const offers = issues[i].offers.nodes;
    for (let o in offers) {
      const offer = offers[o];
      total += offer.units;
      for (let s in offer.subs.nodes) {
        const sub = offer.subs.nodes[s];
        subs += sub.quantity;
      }
    }
  }
  return [total, subs, total - subs > 0 ? total - subs : 0];
}
