import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query useLocalesQuery {
    userLocales: allRosettaLocales(
      filter: { isUserSelectable: { equalTo: true } }
    ) {
      nodes {
        code
        name
        flag
      }
    }
    allLocales: allRosettaLocales {
      nodes {
        code
        name
        flag
      }
    }
  }
`;
