import React, { Component } from 'react'
import { connect } from 'react-redux'
import IdleTimer from 'react-idle-timer'
import { SESSION_TERMINATE_ } from '../state/session'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'

class SessionTimeout extends Component {
  state = {
    open: false
  }

  handleActive = () => {
    this.setState({ isTimedOut: false, open: false })
  }

  handleAction = () => {
    this.setState({ isTimedOut: false, open: false })
  }

  handleIdle = () => {
    const { terminateSession } = this.props
    const { isTimedOut } = this.state
    if (isTimedOut) {
      terminateSession()
    }
    else {
      this.setState({ isTimedOut: true, open: true })
      this.idleTimer.reset()
    }
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleSignOut = () => {
    const { terminateSession } = this.props
    terminateSession()
  }

  render() {
    const { translate } = this.props
    // eslint-disable-next-line no-unused-vars
    const { open, timeout } = this.state
    return (
      <div>
        <IdleTimer 
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.handleActive}
            onIdle={this.handleIdle}
            onAction={this.handleAction}
            debounce={250}
            timeout={15*60*1000}
        />
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{translate.views.session.idle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {translate.views.session.idle_desc}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAutoLogout: state.session.user.preference.autoLogoutDnx, // TODO: use this to prevent logout when MAX is active
  translate: state.view.translate
})

const mapDispatchToProps = dispatch => ({
  terminateSession: () => dispatch(SESSION_TERMINATE_())
})

export default connect(mapStateToProps, mapDispatchToProps)(SessionTimeout)
