import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchQuery } from 'react-relay';
import { environment } from '../relay';
import { useIntl, useLang } from '.';
import useAssetContentQuery from './useAssetContentQuery';

export default function useAssetContent(assetCode) {
  const locale = useLang();
  const [content, setContent] = useState(null);
  const asset = useSelector((state) =>
    state.assets.size > 0 ? state.assets.get(assetCode) : null
  );
  const translate = useIntl();

  useEffect(() => {
    if (assetCode) {
      fetchQuery(environment, useAssetContentQuery, {
        code: assetCode,
        lang: locale,
      }).subscribe({
        next: (data) => {
          setContent(
            data?.assets?.classcontent?.nodes.reduce(
              (a, n) => ({ ...a, [n.contentName]: n.text }),
              {}
            )
          );
        },
      });
    }
  }, [setContent, assetCode, locale]);

  return {
    ...content,
    name: content?.name ?? asset?.name ?? '',
    heading:
      content?.name ??
      translate?.invest[asset.code]?.heading ??
      asset?.name ??
      '',
    description:
      content?.description ??
      translate?.invest[asset.code]?.abstract ??
      asset?.description ??
      '',
  };
}
