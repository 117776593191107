import { useState, useEffect } from 'react';
import { fetchQuery } from 'react-relay';
import { environment } from '../relay';
import useBuildConfigVariablesQuery from './useBuildConfigVariablesQuery';

export default function useBuildConfigVariables() {
  const [vars, setVars] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchQuery(environment, useBuildConfigVariablesQuery).subscribe({
      start: () => {},
      complete: () => {},
      error: (error) => {setError(error)},
      next: (data) => {
        setVars(
          data?.vars?.nodes.reduce((a, n) => ({ ...a, [n.name]: n.value }), {})
        );
      },
    });
  }, []);

  return {vars, error};
}
