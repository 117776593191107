import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, Grid, Typography, useTheme } from '@material-ui/core';
import { ResponsivePie } from '@nivo/pie';
import { v2f } from '../utils/utils';
import { createSelector } from 'reselect';
import { Stone } from '../components';
import { useIntl, useLang, useLink } from '../hooks';
import { formatInteger } from '../utils/number';

const colors = [
  'hsl(3, 70%, 50%)',
  'hsl(13, 70%, 50%)',
  'hsl(23, 70%, 50%)',
  'hsl(33, 70%, 50%)',
  'hsl(43, 70%, 50%)',
  'hsl(53, 70%, 50%)',
  'hsl(63, 70%, 50%)',
  'hsl(182, 70%, 50%)',
  'hsl(172, 70%, 50%)',
  'hsl(162, 70%, 50%)',
  'hsl(152, 70%, 50%)',
  'hsl(142, 70%, 50%)',
  'hsl(274, 70%, 50%)',
  'hsl(126, 70%, 50%)',
  'hsl(181, 70%, 50%)',
];

export const PieShell = ({ variant, ...props }) => {
  const theme = useTheme();

  return (
    <div style={{ height: 160 }}>
      <ResponsivePie
        background={theme.palette.background.default}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        startAngle={variant === 'max' ? -90 : 0}
        endAngle={variant === 'max' ? 90 : 360}
        innerRadius={0.33}
        enableArcLinkLabels={false}
        arcLinkLabel={({ data }) => data.qty}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor='#333333'
        arcLinkLabelsLinkColor={{ from: 'color' }}
        padAngle={0.7}
        cornerRadius={theme.shape.borderRadius}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor='#333333'
        arcLabel={({ data }) => data.id}
        theme={{
          tooltip: {
            container: { background: theme.palette.background.default },
          },
        }}
        {...props}
      />
    </div>
  );
};

export const usePocketChart = () => {
  const { data, grandTotalNotional } = useSelector((state) =>
    selectData(state)
  );
  const locale = useLang();
  const valueFormat = (x) => `${formatInteger(x, locale)} LUSD`;

  const formattedGrandTotalNotional = valueFormat(grandTotalNotional);

  return {
    data,
    grandTotalNotional,
    formattedGrandTotalNotional,
    valueFormat,
  };
};

const PocketChart = ({ direction = 'row', height, variant }) => {
  const { data, formattedGrandTotalNotional, valueFormat } =
    usePocketChart();
  const pushLink = useLink();
  const translate = useIntl();

  return (
    <Card style={{ height }}>
      {data.max > 0 && variant === 'max' ? (
        <Grid container>
          <Grid item sm={direction === 'row' ? 6 : 12}>
            <Box m={1}>
              <Stone variant='h6'>wallet.available</Stone>
            </Box>
            <PieShell data={data.available} variant={variant} />
          </Grid>
          <Grid item sm={direction === 'row' ? 6 : 12}>
            <Box m={1}>
              <Stone variant='h6'>wallet.open</Stone>
            </Box>
            <PieShell data={data.open} variant={variant} />
          </Grid>
        </Grid>
      ) : data.max > 0 ? (
        <Box onClick={() => pushLink('/pocket')}>
          <Box pt={1} pl={2}>
            <Typography variant='h6'>
              {`${translate.nav.pocket} ~ ${formattedGrandTotalNotional}`}
            </Typography>
          </Box>
          <PieShell
            data={data.total}
            valueFormat={valueFormat}
            variant={variant}
          />
        </Box>
      ) : (
        <div />
      )}
    </Card>
  );
};

const selectData = createSelector(
  (state) => state.wallets,
  (state) => state.assets,
  (wallets, assets) => {
    let open = [];
    let available = [];
    let total = [];
    let max = 0;
    let n = 0;
    let grandTotalNotional = 0;

    wallets.forEach((wallet, assetCode) => {
      const asset = assets.get(assetCode);
      if (asset) {
        const openValue = v2f(wallet.open, asset);
        const openNotional = asset.notionalValue * openValue;
        const availableValue = v2f(wallet.available, asset);
        const availableNotional = asset.notionalValue * availableValue;
        if (openNotional > 0 || availableNotional > 0) {
          if (openNotional > max) {
            max = openNotional;
          }
          if (availableNotional > max) {
            max = availableNotional;
          }
          openNotional > 0 &&
            open.push({
              id: assetCode,
              qty: openValue,
              value: openNotional,
              color: colors[n],
            });
          availableNotional > 0 &&
            available.push({
              id: assetCode,
              qty: availableValue,
              value: availableNotional,
              color: colors[n],
            });
          (openNotional > 0 || availableNotional > 0) &&
            total.push({
              id: assetCode,
              qty: openValue + availableValue,
              value: openNotional + availableNotional,
              color: colors[n],
            });
          grandTotalNotional += openNotional + availableNotional;
          ++n;
        }
      }
    });

    return {
      data: { open, available, total, max },
      grandTotalNotional,
    };
  }
);

export default PocketChart;
