import React from 'react';
import PropTypes from 'prop-types';
import NavBar from '../components/layout/NavBar';
import TopAppBar from '../components/layout/TopAppBar';
import BottomAppBar from '../components/layout/BottomAppBar';
import { useNavDrawer } from '../hooks/layout/useNavDrawer';
import BottomNav from './BottomNav';

export const barWidth = 256; // both icons and text rendered (navigation bar)
export const railWidth = 72; // only icons rendered (navigation rail)

const Layout = ({ navType }) => {
  const { isOpen, variant, handlers } = useNavDrawer(navType);

  return (
    <>
      <BottomNav drawerHandlers={handlers} />
      {/*<Hidden smDown>
        <TopAppBar />
        <div className={offset} />
      </Hidden>*/}
      <NavBar
        navType={navType}
        variant={variant}
        isOpen={isOpen}
        drawerHandlers={handlers}
      />
      {/*<Hidden mdUp>
        <BottomAppBar drawerHandlers={handlers} />
      </Hidden>*/}
    </>
  );
};

Layout.propTypes = {
  navType: PropTypes.oneOf(['bar', 'rail']),
  children: PropTypes.node,
};

export default Layout;
