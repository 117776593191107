import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { useIntl } from '../hooks';

const AssetAuthorizationRequired = ({ assetCode }) => {
  const translate = useIntl();
  
  return (
    <div>
      <Typography variant='h6'>
        {assetCode} {translate.assets.auth_required}
      </Typography>
      <Typography paragraph>{translate.assets.not_authorized}</Typography>
      <Button
        variant='contained'
        color='primary'
        onClick={() => (window.location.pathname = `/asset/${assetCode}/`)}
      >
        {translate.nav.more}
      </Button>
    </div>
  );
};

export default AssetAuthorizationRequired;
