import React from 'react'
import PropTypes from 'prop-types'
import { Provider, ReactReduxContext } from 'react-redux'
import { RelayEnvironmentProvider } from 'react-relay'
import { ConnectedRouter } from 'connected-react-router'
import { environment } from '../relay'
import App from './App'

const Root = ({ store, history }) => {
  return (
    <Provider store={store} context={ReactReduxContext}>
      <ConnectedRouter history={history} context={ReactReduxContext}>
        <RelayEnvironmentProvider environment={environment}>
          <App />
        </RelayEnvironmentProvider>
      </ConnectedRouter>
    </Provider>
  )
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export default Root