import { BaseApiService } from './BaseApiService';

class VenueApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/venue/', access, onError);
  }

  post(id, values) {
    return this.http.postFetch(`${id}/`, values)
  }

  post$(id, values) {
    return this.http.postAjax(`${id}/`, values)
  }

  postBoosts(id, values) {
    return this.http.postFetch(`${id}/boosts/`, values)
  }

  postBoosts$(id, values) {
    return this.http.postAjax(`${id}/boosts/`, values)
  }
}

export default VenueApiService;
