/*
 * view.py
 *
 * A store for user-driven state used by views.
 *
 */

import {
  distinctUntilChanged,
  map,
  mergeMap,
  pairwise,
  pluck,
  switchMap,
  take,
  catchError,
  filter,
} from 'rxjs';
import { ofType } from 'redux-observable';
import { MD_SUBSCRIBE_, MD_UNSUBSCRIBE_ } from './md';
import { SESSION_ANONYMOUS, SESSION_FETCHED } from './session';
import { Map } from 'immutable';
import { enqueueSnackbar } from '../components/utils/notistack_redux/redux/actions';
import { v4 as uuidv4 } from 'uuid';
import { handleError } from './utils';
import { TranslateApiService, UserPrefApiService } from '../services';

// action types
export const VIEW_SET_MARKET = 'VIEW:SET_MARKET';
export const VIEW_POPULATE_TICKET = 'VIEW:POPULATE_TICKET';
export const VIEW_CLEAR_TICKET = 'VIEW:CLEAR_TICKET';
export const VIEW_TRANSLATION_FETCHED = 'VIEW:TRANSLATION_FETCHED';
export const VIEW_PAYPAL_FETCHED = 'VIEW:PAYPAL_FETCHED';
export const VIEW_LAYOUT_CHANGE = 'VIEW:LAYOUT_CHANGE';
export const VIEW_LAYOUT_CHANGED = 'VIEW:LAYOUT_CHANGED';
export const VIEW_LAYOUT_SORT_CHANGE = 'VIEW:LAYOUT_SORT_CHANGE';
export const VIEW_LAYOUT_SAVE = 'VIEW:LAYOUT_SAVE';
export const VIEW_MENU_THEME_TOGGLE = 'VIEW:MENU_THEME_TOGGLE';
export const VIEW_MENU_THEME_CHANGE = 'VIEW:MENU_THEME_CHANGE';
export const VIEW_MENU_THEME_CHANGED = 'VIEW:MENU_THEME_CHANGED';
export const VIEW_DASH_CHANGE = 'VIEW:DASH_CHANGE';
export const VIEW_DASH_CHANGED = 'VIEW:DASH_CHANGED';
export const VIEW_DASH_SAVE = 'VIEW:DASH_SAVE';
export const VIEW_SET_LANGUAGE = 'VIEW:SET_LANGUAGE';

// action creators

export const VIEW_SET_MARKET_ = (market) => ({ type: VIEW_SET_MARKET, market });
export const VIEW_POPULATE_TICKET_ = (pick) => ({
  type: VIEW_POPULATE_TICKET,
  pick,
});
export const VIEW_CLEAR_TICKET_ = () => ({ type: VIEW_CLEAR_TICKET });
export const VIEW_TRANSLATION_FETCHED_ = (translate) => ({
  type: VIEW_TRANSLATION_FETCHED,
  translate,
});
export const VIEW_PAYPAL_FETCHED_ = (paypal) => ({
  type: VIEW_PAYPAL_FETCHED,
  paypal,
});
export const VIEW_LAYOUT_CHANGE_ = (layout) => ({
  type: VIEW_LAYOUT_CHANGE,
  layout,
});
export const VIEW_LAYOUT_CHANGED_ = () => ({ type: VIEW_LAYOUT_CHANGED });
export const VIEW_LAYOUT_SORT_CHANGE_ = (sort) => ({
  type: VIEW_LAYOUT_SORT_CHANGE,
  sort,
});
export const VIEW_LAYOUT_SAVE_ = () => ({ type: VIEW_LAYOUT_SAVE });
export const VIEW_MENU_THEME_TOGGLE_ = () => ({ type: VIEW_MENU_THEME_TOGGLE });
export const VIEW_MENU_THEME_CHANGE_ = (menu_theme) => ({ type: VIEW_MENU_THEME_CHANGE, menu_theme, })
export const VIEW_MENU_THEME_CHANGED_ = () => ({ type: VIEW_MENU_THEME_CHANGED });
export const VIEW_DASH_CHANGE_ = (dashlayout) => ({
  type: VIEW_DASH_CHANGE,
  dashlayout,
});
export const VIEW_DASH_CHANGED_ = () => ({ type: VIEW_DASH_CHANGED });
export const VIEW_DASH_SAVE_ = () => ({ type: VIEW_DASH_SAVE });
export const VIEW_SET_LANGUAGE_ = (language) => ({
  type: VIEW_SET_LANGUAGE,
  language,
});

const INITIAL_STATE = {
  market: null,
  pick: null,
  translate: undefined,
  paypal: Map(),
  layout: Map(),
  dashlayout: Map(),
  theme: 'light',
};

// reducer
export const view = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
    case VIEW_SET_MARKET:
      return {
        ...state,
        market: action.market,
        pick: null,
      };
    case VIEW_POPULATE_TICKET:
      return { ...state, pick: { ...action.pick, pick_update: uuidv4() } };
    case VIEW_CLEAR_TICKET:
      return { ...state, pick: null };
    case VIEW_TRANSLATION_FETCHED:
      return {
        ...state,
        translate: action.translate,
      };
    case VIEW_PAYPAL_FETCHED:
      return {
        ...state,
        paypal: action.paypal.reduce(
          (acc, agent) => acc.set(agent.asset, agent),
          state.paypal
        ),
      };
    case VIEW_LAYOUT_CHANGE: {
      let newLayout;

      if (action.layout && action.layout['depth']) {
        newLayout = Map(action.layout);
      } else {
        newLayout = Map({
          depth: {
            h: 24,
            i: 'depth',
            w: 8,
            x: 7,
            y: 0,
            moved: false,
            static: false,
          },
          'order-form': {
            h: 14,
            i: 'order-form',
            w: 9,
            x: 15,
            y: 4,
            moved: false,
            static: false,
          },
          quickbar: {
            h: 4,
            i: 'quickbar',
            w: 9,
            x: 15,
            y: 0,
            moved: false,
            static: false,
          },
          'orders-fills': {
            h: 19,
            i: 'orders-fills',
            w: 24,
            x: 0,
            y: 24,
            moved: false,
            static: false,
          },
          trades: {
            h: 12,
            i: 'trades',
            w: 7,
            x: 0,
            y: 12,
            moved: false,
            static: false,
          },
          pocket: {
            h: 6,
            i: 'pocket',
            w: 9,
            x: 15,
            y: 18,
            moved: false,
            static: false,
          },
          markets: {
            h: 51,
            i: 'markets',
            w: 6,
            x: 0,
            y: 43,
            moved: false,
            order: 'desc',
            static: false,
            orderBy: 0,
          },
          market: {
            h: 6,
            i: 'market',
            w: 7,
            x: 0,
            y: 0,
            moved: false,
            static: false,
          },
          chart: {
            h: 6,
            i: 'chart',
            w: 7,
            x: 0,
            y: 6,
            moved: false,
            static: false,
          },
        });
      }
      return {
        ...state,
        layout: state.layout.mergeDeep(newLayout),
      };
    }
    case VIEW_LAYOUT_SORT_CHANGE:
      return {
        ...state,
        layout: state.layout.mergeDeep(
          Map({
            [action.sort.component]: Map({
              order: action.sort.order,
              orderBy: action.sort.orderBy,
            }),
          })
        ),
      };
    case VIEW_MENU_THEME_CHANGE:
      return {
        ...state,
        theme: action.menu_theme,
      };
    case VIEW_MENU_THEME_TOGGLE:
      return {
        ...state,
        theme: state.theme === 'light' ? 'dark' : 'light',
      };
    case VIEW_DASH_CHANGE: {
      let newDashLayout;
      if (action.dashlayout) {
        newDashLayout = Map(action.dashlayout);
      } else {
        // replace with an initialized map
        newDashLayout = Map({});
      }
      return {
        ...state,
        dashlayout: newDashLayout,
      };
    }
  }
};

export const epics = [
  (action$, state$) =>
    action$.pipe(
      ofType(SESSION_FETCHED),
      filter(
        (action) =>
          action.session.user.preference.language !==
          state$.value.view?.translate?._lang
      ),
      switchMap(() =>
        new TranslateApiService().getAll$().pipe(
          map((response) => VIEW_TRANSLATION_FETCHED_(response)),
          catchError(() => VIEW_TRANSLATION_FETCHED_({}))
        )
      )
    ),

  // eslint-disable-next-line no-unused-vars
  (action$, state$) =>
    action$.pipe(
      ofType(SESSION_ANONYMOUS),
      switchMap(() =>
        new TranslateApiService().getAll$().pipe(
          map((response) => VIEW_TRANSLATION_FETCHED_(response)),
          catchError(() => VIEW_TRANSLATION_FETCHED_({}))
        )
      )
    ),

  (action$, state$) =>
    action$.pipe(
      ofType(VIEW_SET_LANGUAGE),
      switchMap((action) =>
        new TranslateApiService(
          state$.value.session.isAuthorized
            ? state$.value.session.access
            : undefined
        )
          .get$(`${action.language}`)
          .pipe(
            map((response) => VIEW_TRANSLATION_FETCHED_(response)),
            catchError(() => VIEW_TRANSLATION_FETCHED_({}))
          )
      )
    ),

  (action$, state$) =>
    action$.pipe(
      ofType(SESSION_FETCHED),
      switchMap(() =>
        new UserPrefApiService(state$.value.session.access).getAll$().pipe(
          mergeMap((response) => [
            VIEW_LAYOUT_CHANGE_(response.dnx_layout),
            VIEW_DASH_CHANGE_(response.dash_layout),
            VIEW_MENU_THEME_CHANGE_(response.menu_theme),
          ]),
          catchError(
            mergeMap(() => [VIEW_LAYOUT_CHANGED_(), VIEW_DASH_CHANGED_(), VIEW_MENU_THEME_CHANGED_()])
          )
        )
      )
    ),

  // set active market
  //
  /*
  action$ => action$.pipe(
    ofType(SESSION_FETCHED),
    filter(action => action.session.isAuthorized),
    take(1),
    mapTo(VIEW_SET_MARKET_("LUSD|LARS"))),
  */

  // sub/unsubscribe when view changes the active market
  //
  // eslint-disable-next-line no-unused-vars
  (action$, state$) =>
    action$.pipe(
      ofType(VIEW_SET_MARKET),
      take(1), // first time has no former market unsubscribe
      map((action) => MD_SUBSCRIBE_('trades', action.market))
    ),

  // eslint-disable-next-line no-unused-vars
  (action$, state$) =>
    action$.pipe(
      ofType(VIEW_SET_MARKET),
      pluck('market'),
      distinctUntilChanged(),
      pairwise(),
      switchMap((markets) => [
        MD_UNSUBSCRIBE_('trades', markets[0]),
        MD_SUBSCRIBE_('trades', markets[1]),
      ])
    ),

  (action$, state$) =>
      action$.pipe(
        ofType(VIEW_MENU_THEME_TOGGLE),
        mergeMap(() => {
          return new UserPrefApiService(state$.value.session.access)
            .patch$({ menu_theme: state$.value.view.theme })
            .pipe(
              map(() => VIEW_MENU_THEME_CHANGED_()),
              catchError(handleError)
            )
        })
      ),

  (action$, state$) =>
    action$.pipe(
      ofType(VIEW_LAYOUT_SAVE),
      mergeMap(() => {
        return new UserPrefApiService(state$.value.session.access)
          .patch$({ dnx_layout: state$.value.view.layout })
          .pipe(
            map(() =>
              enqueueSnackbar({
                message: 'MAX layout saved!',
                options: {
                  variant: 'success',
                },
              })
            ),
            catchError(handleError)
          );
      })
    ),

  (action$, state$) =>
    action$.pipe(
      ofType(VIEW_DASH_SAVE),
      mergeMap(() => {
        return new UserPrefApiService(state$.value.session.access)
          .patch$({ dash_layout: state$.value.view.dashlayout })
          .pipe(
            map(() => VIEW_DASH_CHANGED_()),
            catchError(handleError)
          );
      })
    ),

  (action$, state$) =>
    action$.pipe(
      ofType(VIEW_LAYOUT_SORT_CHANGE),
      mergeMap(() => {
        return new UserPrefApiService(state$.value.session.access)
          .patch$({ dnx_layout: state$.value.view.layout })
          .pipe(
            map(() => VIEW_LAYOUT_CHANGED_()),
            catchError(handleError)
          );
      })
    ),
];
