import React from 'react';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import { useIntl } from '../hooks';
import Big from 'big.js';
import Humanize from 'humanize-plus';

const magic = x =>
  x < 100
  ? Big(x).toPrecision(2)
  : Humanize.compactInteger(x, 2)
  ;

const Progress = ({ target, total, assetCode, notionalTarget, notionalTotal, notionalAssetCode }) => {
  const translate = useIntl();
  const value = total < target ? 100 * total / target : 100;

  return (
    <div style={{border:1}}>

    <Box p={1} display="flex" flexDirection="column">
    <Box
          alignSelf="flex-end">
        <Typography>
          {magic(target)} {assetCode}
        </Typography>
      </Box>
      { notionalTarget ? (
        <Box
            alignSelf="flex-end">
          <Typography>
            {magic(notionalTarget)} {notionalAssetCode}
          </Typography>
        </Box>
      ) : null}
      <LinearProgress
          color="secondary"
          variant="determinate"
          value={value}
      />
      <Box
          alignSelf="center">
        <Typography color="textSecondary">
          {magic(total)} {assetCode} {translate.progress?.total ?? 'Raised'}
        </Typography>
      </Box>
      {notionalTotal ? (
        <Box
            alignSelf="center">
          <Typography color="textSecondary">
            {magic(notionalTotal)} {notionalAssetCode}
          </Typography>
        </Box>
      ) : null}
    </Box>

    </div>
  )
}

export default Progress;
