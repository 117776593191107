import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { BankIcon, AddCircleOutlineIcon as AddIcon } from '../icons';
import { GraphQL, SelectedMenu } from '../components';
import { useIntl } from '../hooks';
import AddPayPalDialog from './links/AddPayPalDialog.js';
import DestinationPayPalPickerQuery from './DestinationPayPalPickerQuery';

//
// TODO: Implement error, helperText, and disabled props
//

const DestinationPayPalPicker = ({
  /* eslint-disable no-unused-vars */
  asset,
  value,
  onChange,
  error,
  helperText,
  disabled,
  /* eslint-enable no-unused-vars */
}) => {
  // const classes = useStyles();
  const profileId = useSelector((state) => state.session.profileID);
  const translate = useIntl();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [newBank, setNewBank] = useState(false);
  const [recentlyAdded, setRecentlyAdded] = useState(null);

  const handleBankChange = (selectedIndex, bank) => {
    setSelectedIndex(selectedIndex);
    setNewBank(bank.key === null);
    setRecentlyAdded(null);
  };

  const handleAddPayPalClose = () => {
    setNewBank(false);
  };

  const handleAddPayPalSubmit = (recentlyAdded) => {
    setNewBank(false);
    setRecentlyAdded(recentlyAdded);
  };

  const handleAddPayPalError = (recentlyAdded) => {
    setNewBank(false);
    setRecentlyAdded(recentlyAdded);
  };

  return (
    <GraphQL
      query={DestinationPayPalPickerQuery}
      variables={{
        retry: recentlyAdded, // hack to trigger refetch
        filter: {
          securitiesLinkByLinkPtrId: {
            profileId: {
              equalTo: profileId,
            },
            securitiesAssetByAssetId: {
              underlyingId: { equalTo: asset.id },
            },
          },
          bankName: {
            equalTo: 'PayPal',
          },
        },
      }}
    >
      {(gq) => {
        const banks = gq.banks.nodes.map((bank) => ({
          key: bank.link.rowId,
          icon: <BankIcon />,
          label: bank.link.nickname || bank.accountNumber,
        }));
        banks.push({
          key: null,
          icon: <AddIcon />,
          label: `${translate.addNewPayPal}...`,
        });
        let index = selectedIndex;
        if (recentlyAdded) {
          for (let i = 0; i < banks.length; ++i) {
            if (banks[i].key === recentlyAdded) {
              index = i;
            }
          }
        }
        return (
          <>
            <AddPayPalDialog
              asset={asset}
              open={newBank}
              onClose={handleAddPayPalClose}
              onSubmit={(recentlyAdded) => {
                handleAddPayPalSubmit(recentlyAdded);
                onChange(recentlyAdded);
              }}
              onError={handleAddPayPalError}
            />
            <SelectedMenu
              label={translate.paypal.account_subtitle}
              options={banks}
              value={index}
              onSelect={(selectedIndex, bank) => {
                handleBankChange(selectedIndex, bank);
                onChange(banks[selectedIndex].key);
              }}
            />
          </>
        );
      }}
    </GraphQL>
  );
};

export default DestinationPayPalPicker;
