import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query DestinationPayPalPickerQuery(
    $filter: SecuritiesBankaccountlinkFilter!
  ) {
    banks: allSecuritiesBankaccountlinks(filter: $filter) {
      nodes {
        bankName
        link: securitiesLinkByLinkPtrId {
          nickname
          rowId
        }
      }
    }
  }
`;
