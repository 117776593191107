import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query StreetTicketActivityQuery($rowId: Int!) {
    ticket: securitiesStreetticketByTransactionPtrId(transactionPtrId: $rowId) {
      id
      rowId: transactionPtrId
      method: securitiesStreetmethodByMethodId {
        name
        variant
      }
      quantity
      status
      handling
      profile: securitiesProfileByProfileId {
        rowId
        displayName
        name
      }
      ad: securitiesStreetadByAdId {
        rowId
        instructions: info
        quote: securitiesAssetByAssetId {
          rowId
          code
          decimals
          decimals_min: decimalsMin
          icon
          use_static_icon: useStaticIcon
        }
        price
        side
        base: securitiesAssetByQuoteAssetId {
          rowId
          code
          decimals
          decimals_min: decimalsMin
          icon
          use_static_icon: useStaticIcon
        }
        methods: securitiesStreetadMethodsByStreetadId {
          nodes {
            method: securitiesStreetmethodByStreetmethodId {
              name
            }
          }
        }
        dealer: securitiesProfileByProfileId {
          rowId
          displayName
          name
        }
        link: securitiesLinkByLinkId {
          bank: securitiesBankaccountlinkByLinkPtrId {
            beneficiaryName
            bankName
            typeAccount
            accountNumberType
            accountNumber
            bankRoutingNumber
            taxId
            email
          }
        }
      }
      link: securitiesLinkByLinkId {
        bank: securitiesBankaccountlinkByLinkPtrId {
          beneficiaryName
          bankName
          typeAccount
          accountNumberType
          accountNumber
          bankRoutingNumber
          taxId
          email
        }
        eftpush: securitiesEftpushmintlinkByLinkPtrId {
          institution
        }
      }
      cancelation: securitiesStreetticketcancelByTicketId {
        message
      }
      rejection: securitiesStreetticketrejectByTicketId {
        message
      }
      acceptance: securitiesStreetticketacceptByTicketId {
        message
      }
      shipment: securitiesStreetticketshipmentByTicketId {
        message
        ref
      }
      settlement: securitiesStreetsettlementByTicketId {
        message
        id
      }
      transaction: securitiesTransactionByTransactionPtrId {
        time
      }
    }
  }
`;
