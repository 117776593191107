import { createSelector } from 'reselect'
import selectProxyAssets from './selectProxyAssets'

export default createSelector(
  state => state.assets,
  state => selectProxyAssets(state),
  (all, proxies) => {
    const LT = all.get('LT')
    return LT ? proxies.set('LT', LT) : proxies
  }
)