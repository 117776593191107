// Audience is null when that the audience is not being edited
// and is a Set of DiscretionaryGroup.rowId (e.g. perks) when
// it is being edited.
//
// Group/Perk componentes should render in a binary selection state when editing.
//
import { Set } from 'immutable';

export const AUDIENCE_CAPTURE = 'AUDIENCE:CAPTURE';
export const AUDIENCE_ADD = 'AUDIENCE:ADD';
export const AUDIENCE_REMOVE = 'AUDIENCE:REMOVE';
export const AUDIENCE_RELEASE = 'AUDIENCE:RELEASE';

export const AUDIENCE_CAPTURE_ = (audience=[]) =>
  ({ type: AUDIENCE_CAPTURE, audience });
export const AUDIENCE_ADD_ = groupId =>
  ({ type: AUDIENCE_ADD, groupId });
export const AUDIENCE_REMOVE_ = groupId =>
  ({ type: AUDIENCE_REMOVE, groupId });
export const AUDIENCE_RELEASE_ = () =>
  ({ type: AUDIENCE_RELEASE });

// reducer 
//
export const audience = (state=null, action) => {
  switch(action.type) {
    default:
      return state;
    case AUDIENCE_CAPTURE:
      return Set(action.audience);
    case AUDIENCE_ADD:
      return state.add(action.groupId);
    case AUDIENCE_REMOVE:
      return state.remove(action.groupId);
    case AUDIENCE_RELEASE:
      return null;
  }
};
