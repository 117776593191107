import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Returns a memoized instance of the API service for use in react functional components.

export default function useApiService(service, onError = null) {
  const access = useSelector((state) => state.session.access);

  const api = useMemo(() => new service(access, onError), [access, onError, service]);

    return api;
}
