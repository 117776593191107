import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query useSubscribeIssueOfferQuery(
    $assetId: Int!
    $issueFilter: SecuritiesIssueFilter!
  ) {
    asset: securitiesAssetByRowId(rowId: $assetId) {
      issues: securitiesIssuesByOfId(filter: $issueFilter) {
        nodes {
          offers: securitiesIssueoffersByIssueId {
            nodes {
              rowId
              offerDate
              closeDate
              baseAssetId
              quoteAssetId
              basePrice
              feeRate
              maxUnits
              minUnits
              units
              bonusPercent
              securitiesIssueByBonusId {
                ofId
              }
              quote: securitiesProxyassetByQuoteAssetId {
                exchange: securitiesIssueofferexchangeratesByQuoteAssetId(
                  first: 1
                  orderBy: DATE_DESC
                ) {
                  nodes {
                    rate
                  }
                }
              }
              subs: securitiesIssuesubscriptionsByOfferId {
                nodes {
                  quantity
                }
              }
            }
          }
        }
      }
    }
  }
`;
