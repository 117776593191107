import graphql from 'babel-plugin-relay/macro';

export default graphql`
      # Pagination query to be fetched upon calling 'loadMore'.
      # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
      query LedgerFeedPaginationQuery(
        $count: Int!
        $cursor: Cursor
        $orderBy: [SecuritiesLedgersOrderBy!]
        $walletID: ID!
      ) {
        wallet: node(id: $walletID) {
          ...LedgerFeedFragment_wallet @arguments(count: $count, cursor: $cursor, orderBy: $orderBy)
        }
      }
    `;
