import { useDispatch, useSelector } from 'react-redux';

import type { TypedUseSelectorHook } from 'react-redux';
import { AppDispatch, RootState } from '../types/store';

// Use throughout the app instead of `useDispatch` and `useSelector` in typescript modules.
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { default as AssetPreviewFragment } from './AssetPreviewFragment';
export { default as useAccordionGroup } from './useAccordionGroup';
export { default as useAction } from './useAction';
export { default as useActiveFiatAssets } from './useActiveFiatAssets';
export { default as useApiService } from './useApiService';
export { default as useAsset } from './useAsset';
export { default as useAssetClassContent } from './useAssetClassContent';
export { default as useAssetClasses } from './useAssetClasses';
export { default as useAssetContent } from './useAssetContent';
export { default as useAssetExplore } from './useAssetExplore';
export { default as useAssetPreview } from './useAssetPreview';
export { default as useAssetSearch } from './useAssetSearch';
export { default as useAssets } from './useAssets';
export { default as useBuildConfigVariables } from './useBuildConfigVariables';
export { default as useCountries } from './useCountries';
export { default as useDateFns } from './useDateFns';
export { default as useDebounce } from './useDebounce';
export { default as useError } from './useError';
export { default as useFindCurrentOffers } from './useFindCurrentOffers';
export { default as useGetOfferInfo } from './useGetOfferInfo';
export { default as useIntl } from './useIntl';
export { default as useLang } from './useLang';
export { default as useLink } from './useLink';
export { default as useLocales } from './useLocales';
export { default as useMQTT } from './useMQTT';
export { default as useSimpleDialog } from './useSimpleDialog';
export { default as useSnack } from './useSnack';
export { default as useSubscribeIssueOffer } from './useSubscribeIssueOffer';
export { default as useToggle } from './useToggle';
export { default as useViewer } from './useViewer';
export { default as useWallet } from './useWallet';
export { default as useWalletAssets } from './useWalletAssets';
export { default as useWidth } from './useWidth';
