import { useState, useEffect } from 'react';
import { fetchQuery } from 'react-relay';
import { environment } from '../relay';
import { useLang } from '.';
import useAssetPreviewQuery from './useAssetPreviewQuery';

function useAssetPreview(assetId) {
  const locale = useLang();
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchQuery(environment, useAssetPreviewQuery, {
      assetId: assetId,
      language: locale,
    }).subscribe({next: (data) => {
      setData(data);
    }});
  }, [locale, assetId]);

  return data;
}

export default useAssetPreview;
