import React, { useState } from 'react';
import { format } from '../../utils/number';
import { post } from '../../utils/utils';
import { GraphQL } from '../../components';
import { useIntl } from '../../hooks';
import ActivitySkeleton from './ActivitySkeleton';
import StreetTicketActivityForDealerBuy from './StreetTicketActivityForDealerBuy';
import StreetTicketActivityForDealerSell from './StreetTicketActivityForDealerSell';
import StreetTicketActivityForTakerBuy from './StreetTicketActivityForTakerBuy';
import StreetTicketActivityForTakerSell from './StreetTicketActivityForTakerSell';
import StreetTicketActivityQuery from './StreetTicketActivityQuery';

import type { StreetTicketActivityQuery$data } from './__generated__/StreetTicketActivityQuery.graphql';
import type { ActivityProps } from './types';

type FixMeLater = any;

interface Props extends ActivityProps {
  profile: FixMeLater;
}

interface StreetComponentDictionary {
  [key: string]: {
    [key: string] : React.FC<any> // could be more strongly typed, but probably not worth the effort
  }
}

const StreetTicketActivity: React.FC<Props> = ({
  actionId,
  locale,
  onRead,
  profile,
  time,
  type,
  unread,
}) => {
  const translate = useIntl();
  const [nonce, setNonce] = useState(0);
  const [bank, setBank] = useState<string>('');
  // shortening this to 'ref' will result in a console warning
  // about passing refs to function components.
  const [reference, setReference] = useState<string>('');

  const handleAction =
    (action: number, props = {}) =>
    () => {
      post(`/api/street/ticket/${actionId}/`, {
        action,
        ...props /* e.g. gross, fee, net, message, reference number */,
      }).then((r) => r.ok && setNonce(1 + nonce));
    };

  return (
    <GraphQL
      Waiter={ActivitySkeleton}
      variables={{ nonce, rowId: actionId }}
      query={StreetTicketActivityQuery}
    >
      {({ ticket }: StreetTicketActivityQuery$data) => {
        // convenience values
        const role =
          ticket?.ad?.dealer?.name === profile.name ? 'dealer' : 'taker';
        const dealer = role === 'dealer';
        const side = ticket?.ad?.side;
        const closed: boolean =
          ticket?.status === 'canceled' ||
          ticket?.status === 'rejected' ||
          ticket?.status === 'completed' ||
          ticket?.status === 'expired' ||
          ticket?.status === 'returned';
        const statusDescAvailable: boolean =
          side && ticket?.status && role
            ? translate.street_ticket.status_desc[side] &&
              translate.street_ticket.status_desc[side][ticket.status] &&
              translate.street_ticket.status_desc[side][ticket.status][role]
            : false;

        // standard activity content display values
        const asset = ticket?.ad?.base;
        const [quantity] = format(ticket?.quantity, asset, locale);
        const quote_asset = ticket?.ad?.quote;
        const [price] =
          ticket?.ad?.price && quote_asset?.decimals
            ? format(
                Math.round(ticket.ad.price * 10 ** quote_asset.decimals),
                quote_asset,
                locale
              )
            : [undefined];
        const [cost] =
          ticket?.quantity && ticket?.ad?.price
            ? format(ticket.quantity * ticket.ad.price, quote_asset, locale)
            : [undefined];

        const variables = {
          quantity: quantity,
          price: price,
          quote_asset: quote_asset,
          cost: cost,
        };

        // street ticket specific content
        const counterparty = {
          label: `activity.${type}.${side}.${role}.counterparty_label`,
          profile:
            profile?.name === ticket?.profile?.name
              ? ticket?.ad?.dealer
              : ticket?.profile,
        };

        const method = {
          show: !!ticket?.method,
          label: 'street.method.subtitle',
          name: `street.method.${ticket?.method?.name}`,
          instructions: ticket?.ad?.instructions,
          variant: ticket?.method?.variant,
          agent: ticket?.ad?.dealer,
          handling: ticket?.handling,
          linkAsset: asset,
          link: dealer ? ticket?.link : ticket?.ad?.link,
          account:
            ticket?.method?.name === 'paypal'
              ? dealer
                ? ticket?.link?.bank?.accountNumber ?? ''
                : ticket?.ad?.link?.bank?.accountNumber ?? ''
              : '',
          shipmentRef: ticket?.shipment?.ref,
        };

        const status = {
          code: ticket?.status ?? 'ERROR in ticket.status',
          ticket_number: ticket?.rowId,
          ad_number: ticket?.ad?.rowId,
          label: `activity.status_label`,
          text: ticket?.status ? `street_ticket.status.${ticket.status}` : 'ERROR in ticket.status',
          desc: (statusDescAvailable && side && ticket?.status)
            ? translate.street_ticket.status_desc[side][ticket.status][role]
            : null,
        };

        const componentDict: StreetComponentDictionary = {
          dealer: {
            buy: StreetTicketActivityForDealerBuy,
            sell: StreetTicketActivityForDealerSell,
          },
          taker: {
            buy: StreetTicketActivityForTakerBuy,
            sell: StreetTicketActivityForTakerSell,
          },
        };

        // These next components calculate the link & shipping info display,
        // and the available actions for each step of the process. There is
        // one component per role & side combination, for readability.
        const NextComponent = (role && side ) ? componentDict[role][side] : undefined;

        return (type && side && role && NextComponent) ? (
          <NextComponent
            asset={asset}
            badgeType='none'
            label={`activity.${type}.${side}.${role}.label`}
            template={translate.activity[type][side][role].text}
            variables={variables}
            counterparty={counterparty}
            method={method}
            status={status}
            time={time}
            unread={unread && closed}
            onRead={onRead}
            bank={bank}
            setBank={setBank}
            reference={reference}
            setReference={setReference}
            handleAction={handleAction}
          />
        ) : (
          <div />
        );
      }}
    </GraphQL>
  );
};

export default StreetTicketActivity;
