import { useEffect, useState } from "react";

export default function useManifest() {
  const [manifest, setManifest] = useState();

  useEffect(() => fetch('/manifest.json', {
    headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
      }
    })
    .then(r => r.json())
    .then(setManifest),
  []);

  return manifest;
}
