import { BaseApiService } from './BaseApiService';

class MembershipApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/membership/', access, onError);
  }

  postAction(actionId, values) {
    return this.http.postFetch(`${actionId}/`, values)
  }

  postAction$(actionId, values) {
    return this.http.postAjax(`${actionId}/`, values)
  }

}

export default MembershipApiService;
