import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';
import {
  Container,
  Box,
  Button,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useIntl } from '../hooks';
import LanguageChoice from './LanguageChoice';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: 0,
    //borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarLink: {
    padding: theme.spacing(0, 1, 0, 0),
    flexShrink: 0,
    textDecoration: 'none'
  },
  logo: {
    width: 96,
  },
}));

const AnonHeader = ({ registrationContext, title, ...props }) => {
  const classes = useStyles();
  const translate = useIntl();
  const location = useLocation();

  return (
    <Container maxWidth="lg" disableGutters {...props}>
      <Box px={2}>
        <Toolbar className={classes.toolbar}>
          <>
            <Typography
              component='h2'
              variant='h5'
              color='inherit'
              align='center'
              noWrap
              className={classes.toolbarTitle}
            >
              {title}
            </Typography>
            <Link
              to={{ pathname: '/signin', state: { from: location } }}
              className={classes.toolbarLink}
            >
              <Button variant='text' color='default'>
                {translate.user.signin.cta}
              </Button>
            </Link>
            <Link
              to={{
                pathname: '/signup',
                state: { ...registrationContext, from: location },
              }}
              className={classes.toolbarLink}
            >
              <Button variant='contained' color='primary'>
                {translate.user.signup}
              </Button>
            </Link>
            <LanguageChoice />
          </>
        </Toolbar>
      </Box>
    </Container>
  );
};

AnonHeader.propTypes = {
  // sections: PropTypes.array,
  title: PropTypes.string,
};

export default AnonHeader;
